import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { ArrowRightLeft, Search, Trash } from "lucide-react";
import React, { useState } from "react";
import { getAllUsers } from "../../lib/auth";
import { User } from "../../types";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../ui/dialog";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Separator } from "../ui/separator";
import { useUser } from "../../context/UserContext";
import { useChatStore } from "../../store/useChatStore";
import { useConversationStore } from "../../store/useConversationStore";
import { useUIStore } from "../../store/useUIStore";

interface ChatHeaderProps {
  handleAddConversationToUser: (
    conversationId: string,
    newUserId: string,
  ) => void;
}

const ChatHeader = ({ handleAddConversationToUser }: ChatHeaderProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [newUserId, setNewUserId] = useState<string>("");

  const { user } = useUser();
  const isUserSuperadmin = user?.category === "SUPERADMIN";

  const { currentConversationChats } = useChatStore();

  const { removeConversation } = useConversationStore();

  const {
    searchChat,
    setSearchChat,
    isModalOpen,
    setIsModalOpen,
    isAttributionModalOpen,
    setIsAttributionModalOpen,
  } = useUIStore();

  const selectedConversation = useConversationStore((state) => {
    if (!currentConversationChats?.[0]?.conversation_id) return null;

    const convId = currentConversationChats[0].conversation_id;
    return (
      state.conversations.find((c) => c.conversation_id === convId) ||
      state.viewerConversations.find((c) => c.conversation_id === convId)
    );
  });

  const handleDeleteConversation = async () => {
    if (!currentConversationChats || !selectedConversation) return;

    try {
      await removeConversation(selectedConversation.conversation_id);
      useChatStore.getState().setCurrentConversationChats(null);
      setIsModalOpen(false);
      useUIStore.getState().setShouldShowChat(false);
      window.history.replaceState({}, "", "/chat");
    } catch (err) {
      useUIStore
        .getState()
        .setError("Échec de la suppression de la conversation.");
    }
  };

  const loadUsers = async () => {
    const data = await getAllUsers();
    setUsers(data);
  };

  if (!selectedConversation) return null;

  return (
    <>
      <div className="mx-auto hidden h-10 items-center gap-4 text-base font-semibold lg:flex">
        <h1 className="flex h-full items-center overflow-x-auto whitespace-nowrap rounded-xl bg-blue-light px-6 py-3 leading-5">
          {selectedConversation.focus_names.join(", ")}
        </h1>
        <Separator orientation="vertical" />
        <div className="flex h-full items-center gap-2 rounded-xl bg-green-light px-6 py-3">
          {selectedConversation.social_media_formatted_names.map((social) => (
            <div key={social}>
              {social === "tiktok" && <IconBrandTiktok size={20} />}
              {social === "instagram" && <IconBrandInstagram size={20} />}
              {social === "google_news" && <IconBrandGoogle size={20} />}
              {social === "google_maps_reviews" && (
                <IconBrandGoogleMaps size={20} />
              )}
              {social === "linkedin" && <IconBrandLinkedin size={20} />}
              {social === "amazon_reviews" && <IconBrandAmazon size={20} />}
              {social === "facebook" && <IconBrandFacebook size={20} />}
              {social === "x" && <IconBrandX size={20} />}
              {social === "custom" && <IconTool size={20} />}
            </div>
          ))}
        </div>
        <Separator orientation="vertical" />
        <div className="flex h-full w-fit items-center rounded-xl bg-blue-light px-4">
          <Search className="text-dark" />
          <Input
            placeholder="Rechercher..."
            className="w-full rounded-xl border-none text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
            value={searchChat}
            onChange={(e) => setSearchChat(e.target.value)}
          />
        </div>
        <Separator orientation="vertical" />
        <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
          <DialogTrigger asChild>
            <Button className="h-full rounded-xl bg-orange hover:bg-orange/90">
              <Trash className="size-5" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>Supprimer la conversation</DialogHeader>
            <DialogDescription>Cette action est irréversible</DialogDescription>
            <DialogFooter>
              <Button
                variant="outline"
                className="rounded-lg border-dark text-dark"
                onClick={() => setIsModalOpen(false)}
              >
                Annuler
              </Button>
              <Button
                className="mx-auto w-fit rounded-lg bg-orange px-8 text-white hover:bg-orange/90"
                onClick={handleDeleteConversation}
              >
                Supprimer
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Separator orientation="vertical" />

        {isUserSuperadmin && (
          <Dialog
            open={isAttributionModalOpen}
            onOpenChange={setIsAttributionModalOpen}
          >
            <DialogTrigger asChild>
              <Button
                className="h-full rounded-xl bg-green hover:bg-green/90"
                onClick={loadUsers}
              >
                <ArrowRightLeft className="size-5" />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                Attribuez la conversation à un autre utilisateur
              </DialogHeader>
              <DialogDescription>
                <Select onValueChange={(value) => setNewUserId(value)}>
                  <SelectTrigger className="w-64 rounded-xl border border-green font-medium text-dark">
                    <SelectValue placeholder="Sélectionnez un utilisateur" />
                  </SelectTrigger>
                  <SelectContent>
                    {users
                      .sort((a, b) =>
                        (a?.last_name || "").localeCompare(b?.last_name || ""),
                      )
                      .map((user) => (
                        <SelectItem key={user.user_id} value={user.user_id}>
                          {user.first_name} {user.last_name}
                        </SelectItem>
                      ))}
                  </SelectContent>
                </Select>
              </DialogDescription>
              <DialogFooter>
                <Button
                  variant="outline"
                  className="rounded-lg border-dark text-dark"
                  onClick={() => setIsAttributionModalOpen(false)}
                >
                  Annuler
                </Button>
                <Button
                  className="mx-auto w-fit rounded-lg bg-green px-8 text-white hover:bg-green/90"
                  onClick={() =>
                    handleAddConversationToUser(
                      selectedConversation.conversation_id,
                      newUserId,
                    )
                  }
                >
                  Attribuer
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
      </div>
      <Separator
        orientation="horizontal"
        className="mx-auto mt-4 hidden w-11/12 lg:block xl:w-10/12 2xl:w-9/12"
      />
    </>
  );
};

export default React.memo(ChatHeader);
