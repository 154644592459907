// hooks/useConversationRefs.ts
import { useEffect, useRef } from "react";
import { useConversationStore } from "../store/useConversationStore";

export const useConversationRefs = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    useConversationStore.getState().setContainerRef(containerRef);
    useConversationStore.getState().setObserverRef(observerRef);
  }, []);

  return {
    containerRef,
    observerRef,
  };
};
