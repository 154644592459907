import { RefObject } from "react";
import { create } from "zustand";

type UIState = {
  isModalOpen: boolean;
  isAttributionModalOpen: boolean;
  shouldShowChat: boolean;
  error: string;
  searchChat: string;
  messagesEndRef: RefObject<HTMLDivElement> | null;
  textareaRef: RefObject<HTMLTextAreaElement> | null;

  setIsModalOpen: (isOpen: boolean) => void;
  setIsAttributionModalOpen: (isOpen: boolean) => void;
  setShouldShowChat: (shouldShow: boolean) => void;
  setError: (error: string) => void;
  setSearchChat: (search: string) => void;
  setMessagesEndRef: (ref: RefObject<HTMLDivElement>) => void;
  setTextareaRef: (ref: RefObject<HTMLTextAreaElement>) => void;
  adjustTextareaHeight: () => void;
  adjustCursor: (position: number) => void;
};

export const useUIStore = create<UIState>((set, get) => ({
  isModalOpen: false,
  isAttributionModalOpen: false,
  shouldShowChat: false,
  error: "",
  searchChat: "",
  messagesEndRef: null,
  textareaRef: null,

  setIsModalOpen: (isOpen) => set({ isModalOpen: isOpen }),
  setIsAttributionModalOpen: (isOpen) =>
    set({ isAttributionModalOpen: isOpen }),
  setShouldShowChat: (shouldShow) => set({ shouldShowChat: shouldShow }),
  setError: (error) => set({ error }),
  setSearchChat: (search) => set({ searchChat: search }),
  setMessagesEndRef: (ref) => set({ messagesEndRef: ref }),
  setTextareaRef: (ref) => set({ textareaRef: ref }),

  adjustTextareaHeight: () => {
    const { textareaRef } = get();
    if (!textareaRef?.current) return;

    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${Math.min(
      textareaRef.current.scrollHeight,
      300,
    )}px`;
  },

  adjustCursor: (position) => {
    const { textareaRef } = get();
    if (!textareaRef?.current) return;

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(position, position);
      }
    }, 0);
  },
}));
