// ChatMessages.tsx modifié
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "../../context/UserContext";
import { exportChatToPdf, getFeedbackByChatId } from "../../lib/askMoodee";
import { CHATBOT_QUESTIONS } from "../../lib/constants";
import { useChatStore } from "../../store/useChatStore";
import { useConversationStore } from "../../store/useConversationStore";
import { useUIStore } from "../../store/useUIStore";
import { Chat } from "../../types";
import ChatMessage from "./ChatMessage";

interface ChatMessagesProps {
  messagesEndRef: React.RefObject<HTMLDivElement>;
}

const ChatMessages = ({ messagesEndRef }: ChatMessagesProps) => {
  const currentConversationChats = useChatStore(
    (state) => state.currentConversationChats,
  );
  const answerLoading = useChatStore((state) => state.answerLoading);
  const answerFinished = useChatStore((state) => state.answerFinished);
  const moodeeWaitMessage = useChatStore((state) => state.moodeeWaitMessage);
  const { searchChat } = useUIStore();
  const { user } = useUser();
  const [favoritePrompts, setFavoritePrompts] = useState<Chat[]>([]);
  const loadFavoritePrompts = useConversationStore(
    (state) => state.loadFavoritePrompts,
  );

  useEffect(() => {
    const fetchFavorites = async () => {
      const favorites = await loadFavoritePrompts();
      setFavoritePrompts(favorites);
    };

    fetchFavorites();
  }, [loadFavoritePrompts]);

  useEffect(() => {
    const fetchFavorites = async () => {
      const favorites = await loadFavoritePrompts();
      setFavoritePrompts(favorites);
    };

    fetchFavorites();
  }, [loadFavoritePrompts]);

  const deleteMessage = useCallback((chatId: string) => {
    useChatStore.getState().deleteMessage(chatId);
  }, []);

  const handleThumbsUp = useCallback((chatId: string) => {
    useChatStore.getState().handleThumbs(chatId, "UP");
  }, []);

  const handleThumbsDown = useCallback((chatId: string) => {
    useChatStore.getState().handleThumbs(chatId, "DOWN");
  }, []);

  const handleCreateFeedback = useCallback(
    (chatId: string, feedback: string) => {
      useChatStore.getState().handleCreateFeedback(chatId, feedback);
    },
    [],
  );

  const toggleFavorite = useCallback(
    async (chatId: string) => {
      try {
        await useChatStore.getState().toggleFavorite(chatId);
        const updatedFavorites = await loadFavoritePrompts();
        setFavoritePrompts(updatedFavorites);
      } catch (error) {
        console.error("Erreur lors du toggle du favori:", error);
      }
    },
    [loadFavoritePrompts],
  );

  const handleEdit = useCallback((content: string, chatId: string) => {
    useChatStore.getState().setChatContent(content);
    useChatStore.getState().setModifiedChatId(chatId);
  }, []);

  const handleExportPdf = useCallback(async (chatId: string) => {
    await exportChatToPdf(chatId);
  }, []);

  const getFeedback = useCallback(async (chatId: string) => {
    return await getFeedbackByChatId(chatId);
  }, []);

  const handleSelectPrompt = useCallback((prompt: string) => {
    useChatStore.getState().setChatContent(prompt);
    setTimeout(() => {
      useUIStore.getState().adjustTextareaHeight();
      useUIStore.getState().adjustCursor(prompt.length);
    }, 0);
  }, []);

  const filteredMessages = useMemo(() => {
    if (!currentConversationChats) return [];
    if (!searchChat.trim()) return currentConversationChats;

    return currentConversationChats.filter((chat) =>
      chat.content.toLowerCase().includes(searchChat.toLowerCase()),
    );
  }, [currentConversationChats, searchChat]);

  if (!currentConversationChats) return null;

  return (
    <div className="my-5 flex flex-1 flex-col-reverse overflow-y-scroll">
      <div className="mx-auto w-11/12 space-y-4 xl:w-10/12 2xl:w-9/12">
        {filteredMessages.map((chat, idx) => (
          <ChatMessage
            key={chat.chat_id}
            chat={chat}
            user={user}
            isLastMessage={idx === filteredMessages.length - 1}
            answerLoading={answerLoading}
            answerFinished={answerFinished}
            moodeeWaitMessage={moodeeWaitMessage}
            onThumbsUp={handleThumbsUp}
            onThumbsDown={handleThumbsDown}
            onCreateFeedback={handleCreateFeedback}
            onToggleFavorite={toggleFavorite}
            onEdit={handleEdit}
            onDelete={deleteMessage}
            onExportPdf={handleExportPdf}
            getFeedback={getFeedback}
            isFavorite={favoritePrompts.some(
              (fav) => fav.chat_id === chat.chat_id,
            )}
          />
        ))}

        <div ref={messagesEndRef} />

        {answerFinished && currentConversationChats.length <= 1 && (
          <div className="mx-auto flex max-w-[95%] flex-col gap-1">
            <div className="relative w-full">
              <div className="no-scrollbar absolute inset-0 overflow-x-auto">
                <div className="flex w-max gap-2 px-1 py-2">
                  {CHATBOT_QUESTIONS.slice(
                    0,
                    Math.ceil(CHATBOT_QUESTIONS.length / 2),
                  ).map((question) => (
                    <button
                      key={question.label}
                      className="flex-shrink-0 whitespace-nowrap rounded-lg border border-blue bg-transparent px-3 py-2 text-sm text-dark transition-all hover:-translate-y-0.5"
                      onClick={() => handleSelectPrompt(question.prompt)}
                    >
                      {question.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="pointer-events-none absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-white to-transparent"></div>
              <div className="h-12"></div>
            </div>

            <div className="relative w-full">
              <div className="no-scrollbar absolute inset-0 overflow-x-auto">
                <div className="flex w-max gap-2 px-1 py-2">
                  {CHATBOT_QUESTIONS.slice(
                    Math.ceil(CHATBOT_QUESTIONS.length / 2),
                  ).map((question) => (
                    <button
                      key={question.label}
                      className="flex-shrink-0 whitespace-nowrap rounded-lg border border-blue bg-transparent px-3 py-2 text-sm text-dark transition-all hover:-translate-y-0.5"
                      onClick={() => handleSelectPrompt(question.prompt)}
                    >
                      {question.label}
                    </button>
                  ))}
                </div>
              </div>
              <div className="pointer-events-none absolute right-0 top-0 h-full w-8 bg-gradient-to-l from-white to-transparent"></div>
              <div className="h-12"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChatMessages);
