import axios from "axios";
import { refreshToken } from "./auth"; // Adjust the path as necessary

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor to add the access token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor to handle 401 errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newTokens = await refreshToken();

        if (newTokens.access_token) {
          // Store new tokens in localStorage
          localStorage.setItem("accessToken", newTokens.access_token);
          localStorage.setItem("refreshToken", newTokens.refresh_token);

          // Update the Authorization header
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + newTokens.access_token;

          // Retry the original request
          originalRequest.headers["Authorization"] =
            "Bearer " + newTokens.access_token;

          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        // If refresh fails, log out the user or redirect to login
        console.error("Refresh token failed", refreshError);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location.href = "/auth/login"; // Redirect to login page
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
