import { PlusIcon, Search } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import ChatSidebarLoader from "./ChatSidebarLoader";
import { useUser } from "../../context/UserContext";
import { useConversationRefs } from "../../hooks/useConversationRefs";
import { useChatStore } from "../../store/useChatStore";
import { useConversationStore } from "../../store/useConversationStore";
import { useUIStore } from "../../store/useUIStore";

const ChatSidebar = () => {
  const navigate = useNavigate();
  const [showViewerConversations, setShowViewerConversations] = useState(false);

  const { containerRef, observerRef } = useConversationRefs();
  const { user } = useUser();
  const isUserSuperadmin = user?.category === "SUPERADMIN";

  const { setCurrentConversationChats, currentConversationChats } =
    useChatStore();

  const {
    searchConversation,
    setSearchConversation,
    filteredConversations,
    viewerConversations,
    loadConversationHistory,
    isConversationsLoading,
    isLoadingMore,
    hasMore,
  } = useConversationStore();

  useEffect(() => {
    useConversationStore.getState().loadInitialConversations();
    if (isUserSuperadmin) {
      useConversationStore.getState().loadViewerConversations();
    }
  }, [isUserSuperadmin]);

  const currentConversationId = currentConversationChats?.[0]?.conversation_id;
  const selectedConversation = [
    ...filteredConversations,
    ...viewerConversations,
  ].find((conv) => conv.conversation_id === currentConversationId);

  const handleConversationSelect = async (conversationId: string) => {
    try {
      navigate(`?conversation_id=${conversationId}`);
      const history = await loadConversationHistory(conversationId);
      setCurrentConversationChats(history);

      useUIStore.getState().setShouldShowChat(true);
    } catch (err: any) {
      useUIStore.getState().setShouldShowChat(false);
      useUIStore
        .getState()
        .setError(
          err.message || "Échec de la récupération de la conversation.",
        );
    }
  };

  const isSearchActive = searchConversation.trim().length > 0;

  return (
    <div className="flex h-full w-52 flex-col rounded-4xl bg-blue-light px-2 py-4">
      <Button
        className="flex w-full gap-3 rounded-2xl bg-white px-3 font-light text-blue hover:text-white"
        onClick={() => {
          setCurrentConversationChats(null);
          navigate("/chat", { replace: true });
        }}
      >
        <PlusIcon className="size-5" />
        <p className="text-sm">Nouvelle discussion</p>
      </Button>

      <div className="mt-2 flex items-center justify-between gap-2">
        <div className="flex w-full items-center rounded-2xl bg-white px-4">
          <Search className="text-blue" />
          <Input
            placeholder="Rechercher..."
            className="w-full rounded-full border-none py-2 text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
            value={searchConversation}
            onChange={(e) => setSearchConversation(e.target.value)}
          />
        </div>
      </div>

      {isUserSuperadmin && (
        <div className="mt-2 flex items-center gap-2">
          <Label className="text-xs text-dark">Conversations assignées</Label>
          <Checkbox
            checked={showViewerConversations}
            onCheckedChange={() =>
              setShowViewerConversations(!showViewerConversations)
            }
            className={cn(
              "ml-auto transition-colors duration-200",
              showViewerConversations ? "bg-blue text-white" : "text-blue",
            )}
          />
        </div>
      )}

      <div
        ref={containerRef}
        className="no-scrollbar mt-4 flex-1 overflow-y-auto"
      >
        {showViewerConversations ? (
          <ul className="flex flex-col space-y-2">
            {viewerConversations.map((conversation) => (
              <li
                key={conversation.conversation_id}
                className={cn(
                  selectedConversation?.conversation_id ===
                    conversation.conversation_id && "bg-blue",
                  "space-y-1 rounded-xl border border-blue p-2 hover:cursor-pointer",
                )}
                onClick={() => {
                  handleConversationSelect(conversation.conversation_id);
                }}
              >
                <h3 className="no-scrollbar overflow-x-auto whitespace-nowrap text-sm text-dark">
                  {conversation.focus_names.map((focus, index) => (
                    <span
                      key={index}
                      className={cn(
                        selectedConversation?.conversation_id ===
                          conversation.conversation_id && "text-white",
                        "mr-2 inline-block",
                      )}
                    >
                      {focus}
                      {index < conversation.focus_names.length - 1 && " / "}
                    </span>
                  ))}
                </h3>
                <h3 className="no-scrollbar overflow-x-auto whitespace-nowrap text-sm text-dark">
                  {conversation.user_name}
                </h3>
              </li>
            ))}
          </ul>
        ) : (
          <>
            {isConversationsLoading && filteredConversations.length === 0 ? (
              <ChatSidebarLoader />
            ) : (
              <ul className="flex flex-col">
                {filteredConversations.map((conversation) => (
                  <li
                    key={conversation.conversation_id}
                    className={cn(
                      selectedConversation?.conversation_id ===
                        conversation.conversation_id && "bg-blue",
                      "flex w-full items-center justify-between rounded-xl p-2 hover:cursor-pointer",
                    )}
                    onClick={() => {
                      handleConversationSelect(conversation.conversation_id);
                    }}
                  >
                    <h3 className="no-scrollbar overflow-x-auto whitespace-nowrap text-sm text-dark">
                      {conversation.focus_names.map((focus, index) => (
                        <span
                          key={index}
                          className={cn(
                            selectedConversation?.conversation_id ===
                              conversation.conversation_id && "text-white",
                            "mr-2 inline-block",
                          )}
                        >
                          {focus}
                          {index < conversation.focus_names.length - 1 && " / "}
                        </span>
                      ))}
                    </h3>
                  </li>
                ))}

                {!isSearchActive && hasMore && (
                  <div ref={observerRef} className="h-4">
                    {isLoadingMore && (
                      <div className="flex justify-center py-2">
                        <div className="h-5 w-5 animate-spin rounded-full border-2 border-blue border-t-transparent"></div>
                      </div>
                    )}
                  </div>
                )}
              </ul>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(ChatSidebar);
