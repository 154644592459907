import { Warning, WarningIntermediate } from "../types";
import axiosInstance from "./axiosInstance"; // Import the configured axiosInstance

// Function to get all focuses
export const completeWarningWithLLM = async (
  incomplete_warning: WarningIntermediate,
) => {
  try {
    const response = await axiosInstance.post<Warning>(
      "/warning/complete_warning_with_llm",
      incomplete_warning,
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to complete warning with LLM",
      );
    } else {
      throw new Error("Failed to complete warning with LLM");
    }
  }
};

// Function to save a warning
export const createWarning = async (warning: WarningIntermediate) => {
  try {
    const response = await axiosInstance.post<Warning>(
      "/warning/save_warning",
      warning,
    );

    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to add warning to user",
      );
    } else {
      throw new Error("Failed to add warning to user");
    }
  }
};
