import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { format, subMonths, subYears } from "date-fns";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Check,
  Loader2,
  PlusIcon,
  ShoppingBag,
  XIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { getFocusByID } from "../../lib/focus";
import { createNewScraping, getScrapingsByFocus } from "../../lib/scraping";
import { cn } from "../../lib/utils";
import { ScrapingData } from "../../types";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { MultiSelect } from "../ui/multi-select";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Textarea } from "../ui/textarea";
import AnimatedStepper from "./AnimatedStepper";

type KeywordInput = {
  id: number;
  value: string;
};

const FocusConfiguration = () => {
  const { user, socialMedias, focuses } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [productOption, setProductOption] = useState<string>("");
  const [dateOption, setDateOption] = useState<string>("");
  const [newScrapingFocusName, setNewScrapingFocusName] = useState<string>("");
  const [scrapingDescription, setScrapingDescription] = useState<string>("");
  const [existingScrapingFocusId, setExistingScrapingFocusId] =
    useState<string>("");
  const [scrapingStartDate, setScrapingStartDate] = useState<string>("");
  const [scrapingSocialMedias, setScrapingSocialMedias] = useState<string[]>(
    [],
  );
  const [keywordInputs, setKeywordInputs] = useState<KeywordInput[]>([
    { id: 1, value: "" },
  ]);
  const [scrapingKeywordsGroup, setScrapingKeywordsGroup] = useState<
    string[][]
  >([]);
  const [currentStep, setCurrentStep] = useState(1);

  const handleDateOptionChange = (option: string) => {
    setDateOption(option);

    const today = new Date();
    let calculatedDate: Date;

    switch (option) {
      case "1month":
        calculatedDate = subMonths(today, 1);
        break;
      case "3months":
        calculatedDate = subMonths(today, 3);
        break;
      case "6months":
        calculatedDate = subMonths(today, 6);
        break;
      case "1year":
        calculatedDate = subYears(today, 1);
        break;
      case "custom":
        setScrapingStartDate("");
        return;
      default:
        return;
    }

    const formattedDate = format(calculatedDate, "yyyy-MM-dd");
    setScrapingStartDate(formattedDate);
  };

  const handleKeywordInputChange = (id: number, value: string): void => {
    setKeywordInputs((prev) =>
      prev.map((input) =>
        input.id === id ? { ...input, value: value } : input,
      ),
    );
  };

  const handleAddKeywordInput = () => {
    setKeywordInputs((prev) => [...prev, { id: prev.length + 1, value: "" }]);
  };

  const handleAddKeywordGroup = () => {
    setScrapingKeywordsGroup((prev) => [
      ...prev,
      keywordInputs.map((input) => input.value),
    ]);
    setKeywordInputs([{ id: 1, value: "" }]);
  };

  const handleRemoveKeywords = (index: number) => {
    setScrapingKeywordsGroup((prev) => prev.filter((_, i) => i !== index));
  };

  const isStep1Valid = () => {
    return (
      (productOption === "newProduct" && newScrapingFocusName.trim() !== "") ||
      (productOption === "existingProduct" &&
        existingScrapingFocusId.trim() !== "")
    );
  };

  const isStep2Valid = () => {
    return scrapingDescription.trim() !== "";
  };

  const isStep3Valid = () => {
    return scrapingStartDate.trim() !== "";
  };

  const isStep4Valid = () => {
    return scrapingSocialMedias.length > 0;
  };

  const isStep5Valid = () => {
    return scrapingKeywordsGroup.length > 0;
  };

  const isFormValid = () => {
    return (
      isStep1Valid() &&
      isStep2Valid() &&
      isStep3Valid() &&
      isStep4Valid() &&
      isStep5Valid()
    );
  };

  const handleNextStep = () => {
    const validators = [
      isStep1Valid,
      isStep2Valid,
      isStep3Valid,
      isStep4Valid,
      isStep5Valid,
    ];

    if (validators[currentStep - 1]()) {
      setCurrentStep((prev) => Math.min(6, prev + 1));
    }
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => Math.max(1, prev - 1));
  };

  const handleStepChange = (stepId: number) => {
    // Option: Permettre uniquement d'accéder aux étapes déjà complétées
    if (stepId < currentStep) {
      setCurrentStep(stepId);
    }

    // Autre option: Valider toutes les étapes précédentes
    // const allPreviousValid = Array.from({ length: stepId - 1 }, (_, i) => i)
    //   .every(index => validators[index]());
    // if (allPreviousValid) {
    //   setCurrentStep(stepId);
    // }
  };

  const handleStartScraping = async () => {
    if (!isFormValid()) return;

    setIsLoading(true);

    const scrapingDateFormatted = new Date(scrapingStartDate).toISOString();

    try {
      const scrapingData: ScrapingData = {
        keyword_logic: scrapingKeywordsGroup,
        social_media_ids: scrapingSocialMedias,
        start_date: scrapingDateFormatted,
        focus_name:
          productOption === "newProduct" ? newScrapingFocusName : undefined,
        focus_id:
          productOption === "newProduct" ? undefined : existingScrapingFocusId,
        description: scrapingDescription,
      };

      const newScraping = await createNewScraping(scrapingData);

      await getFocusByID(newScraping.focus_id);
      navigate("/focus");
    } catch (err: any) {
      console.error(err.message || "Failed to start scraping.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!existingScrapingFocusId || productOption !== "existingProduct") {
      setScrapingDescription("");
      return;
    }

    const fetchScrapings = async () => {
      try {
        const data = await getScrapingsByFocus(existingScrapingFocusId);

        const lastWithDescription = data
          .filter((scraping) => scraping.description !== null)
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          )[0];

        setScrapingDescription(lastWithDescription?.description || "");
      } catch (error) {
        console.error("Error fetching scrapings:", error);
      }
    };

    fetchScrapings();
  }, [existingScrapingFocusId, productOption]);

  const isFocusLimitReached = focuses.length >= (user?.max_focuses ?? 0);

  // Configuration steps array
  const steps = [
    { id: 1, label: "Sujet", description: "Choisir un sujet à analyser" },
    { id: 2, label: "Contexte", description: "Ajouter une description" },
    { id: 3, label: "Période", description: "Définir la période d'analyse" },
    { id: 4, label: "Réseaux", description: "Sélectionner les plateformes" },
    {
      id: 5,
      label: "Mots-clés",
      description: "Configurer les termes de recherche",
    },
    { id: 6, label: "Résumé", description: "Vérifier et lancer l'analyse" },
  ];

  return (
    <div className="min-h-[calc(100vh-180px)] space-y-12 rounded-4xl border border-gray-200 bg-white p-8 shadow-sm">
      <div className="flex items-center gap-4">
        <ShoppingBag className="size-12 flex-shrink-0 text-blue" />
        <div>
          <h2 className="text-xl font-bold">
            Paramétrez une nouvelle récupération de données
          </h2>
          <p className="text-gray-500">
            Suivez les étapes ci-dessous afin de configurer votre analyse
            rapidement et efficacement
          </p>
        </div>
      </div>

      <AnimatedStepper
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange} // Naviger directement vers une étape
        allowStepClick={true} // Activer/désactiver la navigation par clic
        color="blue"
      />

      <div className="flex items-center gap-5">
        <button
          className="flex items-center justify-center gap-2 rounded-full border border-blue p-4 text-blue disabled:opacity-50"
          onClick={handlePrevStep}
          disabled={currentStep === 1}
        >
          <ArrowLeftIcon className="size-5" />
        </button>
        <div className="mx-auto flex flex-grow flex-col gap-5 rounded-3xl border border-gray-200 bg-white p-5 shadow-sm">
          {currentStep === 1 && (
            <RadioGroup
              value={productOption}
              onValueChange={(value) => {
                setProductOption(value);
                setNewScrapingFocusName("");
              }}
              className="gap-8"
            >
              <div className="space-y-4">
                {isFocusLimitReached && (
                  <div className="flex items-center justify-between">
                    <p className="text-sm text-orange">
                      Vous avez atteint la limite de votre abonnement actuel de{" "}
                      {user?.max_focuses} focus.
                    </p>
                    <Button>Passez au plan supérieur</Button>
                  </div>
                )}

                <div className="flex gap-2">
                  <RadioGroupItem
                    value="newProduct"
                    id="newProduct"
                    className="mt-1 border-blue disabled:cursor-not-allowed data-[state=checked]:bg-blue"
                    disabled={isFocusLimitReached}
                  />
                  <div className={cn(isFocusLimitReached && "opacity-40")}>
                    <label
                      htmlFor="newProduct"
                      className="text-base font-semibold leading-4"
                    >
                      Nouveau focus
                    </label>
                    <p className="text-sm text-gray-500">
                      Créez un nouveau focus
                    </p>
                  </div>
                </div>
                <div
                  className={cn(
                    productOption !== "newProduct" &&
                      "cursor-not-allowed opacity-40",
                    "w-full pl-8",
                  )}
                >
                  <label htmlFor="focusName" className="text-sm font-bold">
                    Choisir un nouveau produit
                  </label>
                  <Input
                    type="text"
                    id="focusName"
                    value={newScrapingFocusName}
                    placeholder="Nom du focus"
                    onChange={(e) => {
                      setNewScrapingFocusName(e.target.value);
                    }}
                    required
                    className="h-10 w-full"
                    disabled={productOption !== "newProduct"}
                  />
                </div>
              </div>

              <div className="space-y-4">
                <div className="flex gap-2">
                  <RadioGroupItem
                    value="existingProduct"
                    id="existingProduct"
                    className="mt-1 border-blue disabled:cursor-not-allowed data-[state=checked]:bg-blue"
                  />
                  <div>
                    <label
                      htmlFor="newProduct"
                      className="text-base font-semibold leading-5"
                    >
                      Focus existant
                    </label>
                    <p className="text-sm text-gray-500">
                      Utilisez un sujet préalablement configuré
                    </p>
                  </div>
                </div>
                <div
                  className={cn(
                    productOption !== "existingProduct" &&
                      "cursor-not-allowed opacity-40",
                    "w-full pl-8",
                  )}
                >
                  <label htmlFor="focusName" className="text-sm font-bold">
                    Choisir un sujet existant
                  </label>
                  <Select
                    onValueChange={(value) => setExistingScrapingFocusId(value)}
                    disabled={productOption !== "existingProduct"}
                    defaultValue={existingScrapingFocusId}
                  >
                    <SelectTrigger className="flex h-10 items-center justify-between rounded-full border border-blue bg-inherit text-sm capitalize text-dark hover:bg-inherit">
                      <SelectValue placeholder="Choisir un focus existant" />
                    </SelectTrigger>
                    <SelectContent>
                      {focuses
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((focus) => (
                          <SelectItem
                            key={focus.focus_id}
                            value={focus.focus_id}
                            className="focus:bg-blue-light"
                          >
                            {focus.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </RadioGroup>
          )}
          {currentStep === 2 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">Description</p>
                <p className="text-sm text-gray-500">
                  Ajoutez du contexte autour de votre focus pour améliorer la
                  pertinence de l'analyse
                </p>
              </div>
              <Textarea
                id="description"
                value={scrapingDescription}
                placeholder="Description du focus"
                onChange={(e) => {
                  setScrapingDescription(e.target.value);
                }}
                className="min-h-24 w-full resize-none rounded-xl border border-blue focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
              />
            </>
          )}

          {currentStep === 3 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Date de départ
                </p>
                <p className="text-sm text-gray-500">
                  Choisissez la date de début pour la récupération des données
                </p>
              </div>

              <RadioGroup
                value={dateOption}
                onValueChange={handleDateOptionChange}
                className="grid grid-cols-2 gap-6 lg:grid-cols-5"
              >
                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value="1month"
                    id="1month"
                    className="disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor="1month"
                    className="text-base font-semibold leading-4"
                  >
                    1 mois
                  </label>
                </div>

                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value="3months"
                    id="3months"
                    className="disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor="3months"
                    className="text-base font-semibold leading-4"
                  >
                    3 mois
                  </label>
                </div>

                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value="6months"
                    id="6months"
                    className="disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor="6months"
                    className="text-base font-semibold leading-4"
                  >
                    6 mois
                  </label>
                </div>

                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value="1year"
                    id="1year"
                    className="disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor="1year"
                    className="text-base font-semibold leading-4"
                  >
                    1 an
                  </label>
                </div>

                <div className="flex items-center gap-2">
                  <RadioGroupItem
                    value="custom"
                    id="custom"
                    className="disabled:cursor-not-allowed"
                  />
                  <label
                    htmlFor="custom"
                    className="text-base font-semibold leading-4"
                  >
                    Personnaliser
                  </label>
                </div>
              </RadioGroup>

              <div
                className={cn(
                  dateOption !== "custom" && "cursor-not-allowed opacity-40",
                  "w-full pl-8",
                )}
              >
                <label htmlFor="startDate" className="text-sm font-bold">
                  Choisir la date
                </label>
                <Input
                  type="date"
                  id="startDate"
                  value={scrapingStartDate}
                  min={
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() - 1),
                    )
                      .toISOString()
                      .split("T")[0]
                  }
                  onChange={(e) => setScrapingStartDate(e.target.value)}
                  required
                  className="h-10 w-full"
                  disabled={dateOption !== "custom"}
                />
              </div>
            </>
          )}

          {currentStep === 4 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Choisissez la ou les plateformes
                </p>
                <p className="text-sm text-gray-500">
                  Choisissez un ou plusieurs réseaux sociaux que vous souhaitez
                  inclure pour l'analyse
                </p>
              </div>

              <MultiSelect
                options={socialMedias.map((social) => ({
                  label: social.name,
                  value: social.social_media_id,
                  icon: (() => {
                    if (social.formatted_name === "tiktok")
                      return IconBrandTiktok;
                    if (social.formatted_name === "google_news")
                      return IconBrandGoogle;
                    if (social.formatted_name === "instagram")
                      return IconBrandInstagram;
                    if (social.formatted_name === "amazon_reviews")
                      return IconBrandAmazon;
                    if (social.formatted_name === "linkedin")
                      return IconBrandLinkedin;
                    if (social.formatted_name === "x") return IconBrandX;
                    if (social.formatted_name === "facebook")
                      return IconBrandFacebook;
                    if (social.formatted_name === "google_maps_reviews")
                      return IconBrandGoogleMaps;
                    return IconTool;
                  })(),
                }))}
                onValueChange={setScrapingSocialMedias}
                defaultValue={scrapingSocialMedias}
                placeholder="Media"
                base="blue"
                hover="blue"
                selected="blue"
                animation={2}
                maxCount={6}
                className="h-12 w-full p-0 px-6"
              />
            </>
          )}

          {currentStep === 5 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Définissez le ou les mots clés de votre recherche
                </p>
                <p className="text-sm text-gray-500">
                  Organisez le ou les mots clés que vous souhaitez analyser en
                  groupes distincs. Chaque groupe de mots clés sera étudié
                  séparément.
                </p>
              </div>

              <div className="space-y-3">
                {keywordInputs.map((input, index) => (
                  <div
                    key={input.id}
                    className={`flex items-center gap-3 ${
                      index === keywordInputs.length - 1 ? "flex-row" : ""
                    }`}
                  >
                    <Input
                      type="text"
                      id={`keyword${input.id}`}
                      value={input.value}
                      placeholder={`Mot clé ${input.id}`}
                      onChange={(e) =>
                        handleKeywordInputChange(input.id, e.target.value)
                      }
                      required
                      className={`h-10 w-full ${
                        index === keywordInputs.length - 1 ? "flex-grow" : ""
                      }`}
                    />
                    {index === keywordInputs.length - 1 && (
                      <Button
                        onClick={handleAddKeywordInput}
                        className="w-fit gap-3 text-xs"
                        size="lg"
                      >
                        <span>Ajouter un mot clé au groupe</span>
                        <PlusIcon className="size-5 rounded-md bg-white p-0.5 text-blue" />
                      </Button>
                    )}
                  </div>
                ))}
              </div>

              <Button
                onClick={handleAddKeywordGroup}
                className="mx-auto w-fit py-3"
                variant="outline"
                hover="jumping"
                disabled={keywordInputs.every((input) => !input.value)}
              >
                Valider ce/ces mot(s)-clé(s)
              </Button>

              {/* Displaying the added keywords arrays */}
              <div className="col-span-full -mt-2">
                <ul className="flex flex-wrap items-center gap-2">
                  {scrapingKeywordsGroup.map((keywordsArray, index) => (
                    <li
                      className="flex w-fit items-center gap-3 rounded-full bg-blue-light py-0.5 pl-4 pr-2 text-sm"
                      key={index}
                    >
                      {keywordsArray.join(" / ")}
                      <button
                        onClick={() => handleRemoveKeywords(index)}
                        className="text-white"
                      >
                        <XIcon className="size-4 rounded-full bg-orange p-0.5 text-dark" />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}

          {currentStep === 6 && (
            <>
              <div>
                <h3 className="text-lg font-semibold">
                  Confirmez votre configuration
                </h3>

                <p className="text-sm text-gray-400">
                  Vérifiez les informations suivantes avant de lancer la
                  récupération des données.
                </p>
              </div>

              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  <p className="font-semibold">Produit :</p>
                  <p>
                    {productOption === "newProduct"
                      ? newScrapingFocusName
                      : focuses.find(
                          (focus) => focus.focus_id === existingScrapingFocusId,
                        )?.name}
                  </p>
                </div>

                <div className="flex items-start">
                  <p className="flex-shrink-0 font-semibold">Description :</p>
                  <p className="flex-1 pl-2">{scrapingDescription}</p>
                </div>

                <div className="flex items-center gap-2">
                  <p className="font-semibold">Date de départ :</p>
                  <p>{scrapingStartDate}</p>
                </div>

                <div className="flex items-center gap-2">
                  <p className="font-semibold">Réseaux sociaux :</p>
                  <div className="flex flex-wrap gap-2">
                    {scrapingSocialMedias.map((socialMedia) => (
                      <span
                        key={socialMedia}
                        className="rounded-full bg-blue-light px-3 py-1 text-sm"
                      >
                        {
                          socialMedias.find(
                            (social) => social.social_media_id === socialMedia,
                          )?.name
                        }
                      </span>
                    ))}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <p className="font-semibold">Mots clés :</p>
                  {scrapingKeywordsGroup.map((keywordsArray, index) => (
                    <div
                      key={index}
                      className="mb-1 mr-2 inline-block rounded-full bg-blue-light px-3 py-1 text-sm"
                    >
                      {keywordsArray.join(" / ")}
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        <button
          className="flex items-center justify-center gap-2 rounded-full border border-blue bg-blue p-4 text-white disabled:opacity-30"
          onClick={() => {
            if (currentStep < 6) {
              handleNextStep();
            }
            if (currentStep === 6) {
              handleStartScraping();
            }
          }}
          disabled={
            (currentStep === 1 && !isStep1Valid()) ||
            (currentStep === 2 && !isStep2Valid()) ||
            (currentStep === 3 && !isStep3Valid()) ||
            (currentStep === 4 && !isStep4Valid()) ||
            (currentStep === 5 && !isStep5Valid()) ||
            (currentStep === 6 && (!isFormValid() || isLoading))
          }
        >
          {currentStep < 6 ? (
            <ArrowRightIcon className="size-5" />
          ) : isLoading ? (
            <Loader2 className="size-5 animate-spin" />
          ) : (
            <Check className="size-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default FocusConfiguration;
