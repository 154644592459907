// TabContent.tsx
import { motion } from "framer-motion";
import { memo } from "react";
import { cn } from "../../lib/utils";
import FocusConfiguration from "./FocusConfiguration";
import WarningConfiguration from "./WarningConfiguration";

interface TabContentProps {
  tab: {
    value: string;
  };
  isActive: boolean;
  hovering: boolean;
  index: number;
  isFirst: boolean;
}

const TabContent = memo(
  ({ tab, isActive, hovering, index, isFirst }: TabContentProps) => {
    return (
      <motion.div
        layoutId={tab.value}
        style={{
          scale: 1 - index * 0.1,
          top: hovering ? index * -45 : 0,
          zIndex: -index,
        }}
        animate={{
          y: isFirst ? [0, 40, 0] : 0,
        }}
        className={cn(
          "absolute left-0 top-0 w-full",
          isActive || hovering ? "opacity-100" : "opacity-0",
        )}
      >
        {tab.value === "focus" && <FocusConfiguration />}
        {tab.value === "alerts" && <WarningConfiguration />}
      </motion.div>
    );
  },
);

TabContent.displayName = "TabContent";

export default TabContent;
