import { create } from "zustand";
import { getSecureImage } from "../lib/cloudStorage";

interface ImageState {
  imageCache: Map<string, string>;
  loadingImages: string[];

  getImageSrc: (imageId: string) => Promise<string>;
  isLoading: (imageId: string) => boolean;
}

export const useImageStore = create<ImageState>((set, get) => ({
  imageCache: new Map(),
  loadingImages: [],

  isLoading: (imageId: string) => {
    return get().loadingImages.includes(imageId);
  },

  getImageSrc: async (imageId: string) => {
    const { imageCache, loadingImages } = get();

    if (imageCache.has(imageId)) {
      return imageCache.get(imageId) || "";
    }

    if (loadingImages.includes(imageId)) {
      return new Promise((resolve) => {
        const checkCache = setInterval(() => {
          if (get().imageCache.has(imageId)) {
            clearInterval(checkCache);
            resolve(get().imageCache.get(imageId) || "");
          }
        }, 100);
      });
    }

    set((state) => ({
      loadingImages: [...state.loadingImages, imageId],
    }));

    try {
      const blob = await getSecureImage(imageId);
      const objectUrl = URL.createObjectURL(blob);

      set((state) => {
        const newCache = new Map(state.imageCache);
        newCache.set(imageId, objectUrl);

        return {
          imageCache: newCache,
          loadingImages: state.loadingImages.filter((id) => id !== imageId),
        };
      });

      return objectUrl;
    } catch (error) {
      set((state) => ({
        loadingImages: state.loadingImages.filter((id) => id !== imageId),
      }));
      throw error;
    }
  },
}));
