import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { ArrowLeftIcon, ArrowRightIcon, Bell, Check } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useUser } from "../../context/UserContext";
import { completeWarningWithLLM, createWarning } from "../../lib/warning";
import { Input } from "../ui/input";
import { MultiSelect } from "../ui/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Textarea } from "../ui/textarea";
import AnimatedStepper from "./AnimatedStepper";

const warningSchedulingOptions = [
  { label: "Tous les jours", value: "daily" },
  { label: "Toutes les semaines", value: "weekly" },
  { label: "Tous les mois", value: "monthly" },
];

const WarningConfiguration = () => {
  const { socialMedias, focuses } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [warningUserDescription, setWarningUserDescription] =
    useState<string>("");
  const [warningFocusId, setWarningFocusId] = useState<string>("");
  const [warningStartDate, setWarningStartDate] = useState<string>("");
  const [warningSocialMedias, setWarningSocialMedias] = useState<string[]>([]);
  const [warningScheduling, setWarningScheduling] = useState<string>("");
  const [warningLLMDescription, setWarningLLMDescription] =
    useState<string>("");
  const [isLLMLoading, setIsLLMLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [warningWaitMessage, setWarningWaitMessage] = useState("");

  const isStep1Valid = () => {
    return warningFocusId.trim() !== "";
  };

  const isStep2Valid = () => {
    return warningSocialMedias.length > 0;
  };

  const isStep3Valid = () => {
    return warningStartDate.trim() !== "";
  };

  const isStep4Valid = () => {
    return warningScheduling.trim() !== "";
  };

  const isStep5Valid = () => {
    return warningUserDescription.trim() !== "";
  };

  const isFormValid = () => {
    return (
      isStep1Valid() &&
      isStep2Valid() &&
      isStep3Valid() &&
      isStep4Valid() &&
      isStep5Valid()
    );
  };

  const handleNextStep = () => {
    const validators = [
      isStep1Valid,
      isStep2Valid,
      isStep3Valid,
      isStep4Valid,
      isStep5Valid,
    ];

    if (validators[currentStep - 1]()) {
      setCurrentStep((prev) => Math.min(6, prev + 1));
    }
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => Math.max(1, prev - 1));
  };

  const handleStepChange = (stepId: number) => {
    // Option: Permettre uniquement d'accéder aux étapes déjà complétées
    if (stepId < currentStep) {
      setCurrentStep(stepId);
    }
  };

  const handleCreateAlert = async () => {
    if (!isFormValid()) return;

    setIsLoading(true);

    const warningDateFormatted = new Date(warningStartDate).toISOString();

    try {
      const warningData = {
        social_media_ids: warningSocialMedias,
        start_date: warningDateFormatted,
        focus_id: warningFocusId,
        user_description: warningUserDescription,
        scheduling: warningScheduling,
        active: true,
        llm_description: warningLLMDescription,
      };

      await createWarning(warningData);
      toast("Alerte créée avec succès");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (err: any) {
      console.error(err.message || "Failed to start scraping.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompleteWarningWithLLM = async () => {
    if (!isFormValid()) return;

    setIsLLMLoading(true);

    try {
      const warningDateFormatted = new Date(warningStartDate).toISOString();

      const warningData = {
        social_media_ids: warningSocialMedias,
        start_date: warningDateFormatted,
        focus_id: warningFocusId,
        user_description: warningUserDescription,
        scheduling: warningScheduling,
        active: true,
        llm_description: null,
      };

      const response = await completeWarningWithLLM(warningData);
      setWarningLLMDescription(response.llm_description || "");
    } catch (err: any) {
      console.error(err.message || "Failed to start scraping.");
    } finally {
      setIsLLMLoading(false);
    }
  };

  // Configuration steps array
  const steps = [
    { id: 1, label: "Focus" },
    { id: 2, label: "Réseaux sociaux" },
    { id: 3, label: "Date de début" },
    { id: 4, label: "Fréquence" },
    { id: 5, label: "Description" },
    { id: 6, label: "Résumé" },
  ];

  useEffect(() => {
    if (isLLMLoading) {
      const messages = [
        "Analyse de l'alerte...",
        "Interprétation des paramètres...",
        "Raisonnement...",
        "Génération du résumé de l'alerte...",
      ];
      // Fonction pour générer une durée aléatoire entre 5000 et 8000 ms
      const getRandomDuration = () =>
        Math.floor(Math.random() * (8000 - 5000 + 1) + 5000);

      // Premier message
      setWarningWaitMessage(messages[0]);

      // Messages suivants avec délais cumulatifs aléatoires
      let cumulativeDelay = 0;

      messages.slice(1).forEach((msg, index) => {
        cumulativeDelay += getRandomDuration();

        setTimeout(() => {
          setWarningWaitMessage(msg);
        }, cumulativeDelay);
      });
    }
  }, [isLLMLoading]);

  return (
    <div className="min-h-[calc(100vh-180px)] rounded-4xl border border-gray-200 bg-white p-8 shadow-sm">
      <div className="mb-6 flex items-center gap-4">
        <Bell className="size-12 flex-shrink-0 text-orange" />
        <div>
          <h2 className="text-xl font-bold">Paramétrez une nouvelle alerte</h2>
          <p className="text-gray-500">
            Suivez les étapes ci-dessous afin de configurer votre alerte
          </p>
        </div>
      </div>

      <AnimatedStepper
        steps={steps}
        currentStep={currentStep}
        onStepChange={handleStepChange} // Naviger directement vers une étape
        allowStepClick={true} // Activer/désactiver la navigation par clic
        color="orange"
      />

      <div className="flex items-center gap-5">
        <button
          className="flex items-center justify-center gap-2 rounded-full border border-orange p-4 text-orange disabled:opacity-50"
          onClick={handlePrevStep}
          disabled={currentStep === 1}
        >
          <ArrowLeftIcon className="size-5" />
        </button>

        <div className="mx-auto flex flex-grow flex-col gap-5 rounded-3xl border border-gray-200 bg-white p-5 shadow-sm">
          {currentStep === 1 && (
            <div className="space-y-4">
              <h3 className="text-base font-semibold leading-4">Focus</h3>
              <label htmlFor="focusName" className="text-sm text-gray-500">
                Choisir le focus sur lequel vous voulez être alerté
              </label>
              <Select
                onValueChange={(value) => setWarningFocusId(value)}
                defaultValue={warningFocusId}
              >
                <SelectTrigger className="flex h-10 items-center justify-between rounded-full border border-orange bg-inherit text-sm text-dark hover:bg-inherit">
                  <SelectValue placeholder="Choisir un focus existant" />
                </SelectTrigger>
                <SelectContent>
                  {focuses
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((focus) => (
                      <SelectItem
                        key={focus.focus_id}
                        value={focus.focus_id}
                        className="focus:bg-orange-light"
                      >
                        {focus.name}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
          )}

          {currentStep === 2 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Choisissez la ou les plateformes
                </p>
                <p className="text-sm text-gray-500">
                  Choisissez le ou les réseaux sociaux que vous souhaitez
                  analyser pour votre alerte
                </p>
              </div>

              <MultiSelect
                options={socialMedias.map((social) => ({
                  label: social.name,
                  value: social.social_media_id,
                  icon: (() => {
                    if (social.formatted_name === "tiktok")
                      return IconBrandTiktok;
                    if (social.formatted_name === "google_news")
                      return IconBrandGoogle;
                    if (social.formatted_name === "instagram")
                      return IconBrandInstagram;
                    if (social.formatted_name === "amazon_reviews")
                      return IconBrandAmazon;
                    if (social.formatted_name === "linkedin")
                      return IconBrandLinkedin;
                    if (social.formatted_name === "x") return IconBrandX;
                    if (social.formatted_name === "facebook")
                      return IconBrandFacebook;
                    if (social.formatted_name === "google_maps_reviews")
                      return IconBrandGoogleMaps;
                    return IconTool;
                  })(),
                }))}
                onValueChange={setWarningSocialMedias}
                defaultValue={warningSocialMedias}
                placeholder="Media"
                animation={2}
                maxCount={3}
                className="h-12 max-w-full p-0 px-6"
                base="orange"
                hover="orange"
                selected="orange"
              />
            </>
          )}

          {currentStep === 3 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Date de départ
                </p>
                <p className="text-sm text-gray-500">
                  Choisissez la date de début pour la récupération des données
                </p>
              </div>

              <Input
                type="date"
                id="startDate"
                value={warningStartDate}
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => setWarningStartDate(e.target.value)}
                required
                className="h-10 w-full border-orange ring-orange"
              />
            </>
          )}

          {currentStep === 4 && (
            <div className="space-y-4">
              <h3 className="text-base font-semibold leading-4">Fréquence</h3>
              <label htmlFor="focusName" className="text-sm text-gray-500">
                Choisir la fréquence à laquelle vous souhaitez être alerté
              </label>
              <Select
                onValueChange={(value) => setWarningScheduling(value)}
                defaultValue={warningScheduling}
              >
                <SelectTrigger className="flex h-10 items-center justify-between rounded-full border border-orange bg-inherit text-sm text-dark hover:bg-inherit">
                  <SelectValue placeholder="Choisir la fréquence de l'alerte" />
                </SelectTrigger>
                <SelectContent>
                  {warningSchedulingOptions.map((option) => (
                    <SelectItem
                      key={option.value}
                      value={option.value}
                      className="capit focus:bg-orange-light"
                    >
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}
          {currentStep === 5 && (
            <>
              <div>
                <p className="text-base font-semibold leading-4">
                  Description de l'alerte
                </p>
                <p className="text-sm text-gray-500">
                  Décrivez en language naturel ce que vous souhaitez surveillez
                  avec cette alerte
                </p>
              </div>
              <Textarea
                id="description"
                value={warningUserDescription}
                placeholder="Description de l'alerte"
                onChange={(e) => {
                  setWarningUserDescription(e.target.value);
                }}
                className="min-h-28 w-full rounded-xl border border-orange focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
              />
            </>
          )}

          {currentStep === 6 && (
            <>
              <div>
                <h3 className="text-lg font-semibold">
                  Confirmez votre configuration d'alerte
                </h3>

                <p className="text-sm text-gray-400">
                  Vérifiez les informations suivantes avant de créer votre
                  alerte
                </p>
              </div>

              <div className="space-y-4">
                <div className="gap-4 space-y-4 md:grid md:grid-cols-3 md:space-y-0">
                  <div>
                    <p className="font-semibold">Produit</p>
                    <p>
                      {
                        focuses.find(
                          (focus) => focus.focus_id === warningFocusId,
                        )?.name
                      }
                    </p>
                  </div>

                  <div>
                    <p className="font-semibold">Date de début</p>
                    <p>{warningStartDate}</p>
                  </div>

                  <div>
                    <p className="font-semibold">Fréquence</p>
                    <p>
                      {
                        warningSchedulingOptions.find(
                          (option) => option.value === warningScheduling,
                        )?.label
                      }
                    </p>
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Réseaux sociaux</p>
                  <div className="flex flex-wrap gap-2">
                    {warningSocialMedias.map((socialMedia) => (
                      <span
                        key={socialMedia}
                        className="rounded-full bg-orange-light px-3 py-1 text-sm"
                      >
                        {
                          socialMedias.find(
                            (social) => social.social_media_id === socialMedia,
                          )?.name
                        }
                      </span>
                    ))}
                  </div>
                </div>

                <div>
                  <p className="font-semibold">Description :</p>
                  <p>{warningUserDescription}</p>
                </div>

                <div>
                  <p className="font-semibold">
                    Résumé de l'alerte par Moodee:
                  </p>
                  {isLLMLoading ? (
                    <p className="animate-pulse text-gray-400 transition">
                      {warningWaitMessage}
                    </p>
                  ) : (
                    <p>{warningLLMDescription}</p>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <button
          className="flex items-center justify-center gap-2 rounded-full border border-orange bg-orange p-4 text-white disabled:opacity-30"
          onClick={() => {
            if (currentStep === 5) {
              handleCompleteWarningWithLLM();
            }
            if (currentStep === 6) {
              handleCreateAlert();
            }
            if (currentStep < 6) {
              handleNextStep();
            }
          }}
          disabled={
            (currentStep === 1 && !isStep1Valid()) ||
            (currentStep === 2 && !isStep2Valid()) ||
            (currentStep === 3 && !isStep3Valid()) ||
            (currentStep === 4 && !isStep4Valid()) ||
            (currentStep === 5 && !isStep5Valid()) ||
            (currentStep === 6 && (!isFormValid() || isLLMLoading))
          }
        >
          {currentStep < 6 ? (
            <ArrowRightIcon className="size-5" />
          ) : (
            <Check className="size-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default WarningConfiguration;
