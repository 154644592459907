import { ChevronDownIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useUser } from "../context/UserContext";
import AvatarUser from "./Avatar";
import TabContent from "./configuration/TabContent";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "./ui/dropdown";

type Tab = {
  title: string;
  value: string;
  content?: string | React.ReactNode | any;
};

const Configuration: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useUser();

  const tabs = useMemo(() => [{ title: "Focus", value: "focus" }], []);

  const findTabByValue = useCallback(
    (value: string) => {
      return tabs.find((tab) => tab.value === value) || tabs[0];
    },
    [tabs],
  );

  // Initialize state based on URL params
  const initialState = useMemo(() => {
    const initialTabParam = searchParams.get("tab");
    const initialTab = findTabByValue(initialTabParam || "focus");
    const initialTabs = [...tabs];
    if (initialTab.value !== tabs[0].value) {
      const idx = tabs.findIndex((tab) => tab.value === initialTab.value);
      const [selectedTab] = initialTabs.splice(idx, 1);
      initialTabs.unshift(selectedTab);
    }
    return { initialTab, initialTabs };
  }, [searchParams, findTabByValue, tabs]);

  const [active, setActive] = useState<Tab>(initialState.initialTab);
  const [currentTabs, setCurrentTabs] = useState<Tab[]>(
    initialState.initialTabs,
  );
  const [hovering, setHovering] = useState(false);

  const moveSelectedTabToTop = useCallback(
    (idx: number) => {
      const newTabs = [...tabs];
      const selectedTab = newTabs.splice(idx, 1);
      newTabs.unshift(selectedTab[0]);
      setCurrentTabs(newTabs);
      setActive(newTabs[0]);
      setSearchParams({ tab: newTabs[0].value });
    },
    [setSearchParams, tabs],
  );

  useEffect(() => {
    const tabParam = searchParams.get("tab");
    if (tabParam && tabParam !== active.value) {
      const targetTab = findTabByValue(tabParam);
      const targetIndex = tabs.findIndex(
        (tab) => tab.value === targetTab.value,
      );
      if (targetIndex !== -1) {
        moveSelectedTabToTop(targetIndex);
      }
    }
  }, [searchParams, active.value, findTabByValue, moveSelectedTabToTop, tabs]);

  return (
    <div className="px-4 py-8 sm:px-8 lg:px-12">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-12">
          <h1 className="w-fit text-4xl font-semibold text-blue">
            Paramétrage
          </h1>
        </div>

        {/* <div className="relative -ml-20 flex w-fit flex-row items-center justify-start gap-3 overflow-auto rounded-2xl border border-gray-200 p-2 shadow-sm">
          {tabs.map((tab) => (
            <TabButton
              key={tab.value}
              tab={tab}
              isActive={active.value === tab.value}
              onSelect={() => {
                const targetIndex = tabs.findIndex(
                  (t) => t.value === tab.value,
                );
                moveSelectedTabToTop(targetIndex);
              }}
              onHoverChange={setHovering}
            />
          ))}
        </div> */}

        <DropdownMenu>
          <DropdownMenuTrigger className="focus:ring-none flex items-center gap-2 focus:border-none">
            <AvatarUser user={user} className="size-10" />
            <ChevronDownIcon className="size-6" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>Mon compte</DropdownMenuLabel>
            <DropdownMenuItem>
              <Link to="/profile">Informations personnelles</Link>
            </DropdownMenuItem>
            <DropdownMenuItem disabled>Notifications</DropdownMenuItem>
            <DropdownMenuItem disabled>Paiement</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="relative mt-8 flex h-[calc(100vh-180px)] w-full flex-col items-start justify-start gap-6 [perspective:1000px]">
        <div
          className="relative h-[calc(100vh-180px)] w-full"
          key={active.value}
        >
          {currentTabs.map((tab, idx) => (
            <TabContent
              key={tab.value}
              tab={tab}
              isActive={active.value === tab.value}
              hovering={hovering}
              index={idx}
              isFirst={tab.value === currentTabs[0].value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Configuration;
