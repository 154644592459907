import React, { useEffect, useState } from "react";
import { useImageStore } from "../store/useImageStore";

interface SecureImageProps {
  imageId: string;
  className?: string;
}

const SecureImage: React.FC<SecureImageProps> = ({ imageId, className }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const getImageSrc = useImageStore((state) => state.getImageSrc);

  useEffect(() => {
    let isMounted = true;

    const loadImage = async () => {
      try {
        const url = await getImageSrc(imageId);

        if (isMounted) {
          if (url) {
            setImageSrc(url);
          } else {
            setError(true);
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(true);
        }
      }
    };

    loadImage();

    return () => {
      isMounted = false;
    };
  }, [imageId, getImageSrc]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setError(true);
  };

  if (error) {
    return (
      <div className="flex h-40 w-full items-center justify-center bg-gray-100">
        <p className="text-sm text-red-500">Image non disponible</p>
      </div>
    );
  }

  return (
    <div className="relative">
      {isLoading && (
        <div className="flex h-40 w-full items-center justify-center bg-gray-100">
          <div className="h-8 w-8 animate-spin rounded-full border-2 border-blue border-t-transparent"></div>
        </div>
      )}
      {imageSrc && (
        <img
          src={imageSrc}
          alt="contenu sécurisé"
          className={`${className || "max-w-full rounded"} ${isLoading ? "hidden" : "block"}`}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      )}
    </div>
  );
};

export default React.memo(SecureImage);
