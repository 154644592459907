import { Chat, Conversation, Feedback } from "../types";
import axiosInstance from "./axiosInstance";
import { URLS } from "./constants";

export const startNewChat = async (conversationData: {
  focus_ids: string[];
  social_media_ids: string[];
}): Promise<Conversation> => {
  try {
    const response = await axiosInstance.post(
      "/chatbotv2/new_conversation",
      conversationData,
    );
    return response.data;
  } catch (error: any) {
    // Type assertion
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating chat");
    }
  }
};

export const getConversationsByUserPaginated = async (
  page: number = 1,
  limit: number = 15,
): Promise<{
  conversations: Conversation[];
  has_more: boolean;
  total: number;
}> => {
  try {
    const response = await axiosInstance.get("/chatbotv2/conversations", {
      params: {
        page,
        limit,
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversations");
    }
  }
};

export const getAllConversationsByUser = async (): Promise<Conversation[]> => {
  try {
    const response = await axiosInstance.get("/chatbotv2/conversations");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversations");
    }
  }
};

export const getConversationHistory = async (
  conversationId: string,
): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get(
      `/chatbotv2/history`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching conversation history");
    }
  }
};

export const postFirstMessage = async (
  conversation_id: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.post(
      "/chatbotv2/first_message",
      { conversation_id }, // Envoi d'un objet contenant conversation_id
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching first message");
    }
  }
};

export const askQuestionToMoodee = async (
  conversation_id: string,
  content: string,
  onChunkReceived: (
    chunk: string,
    metadata?: { user_id: string; moodee_id: string | null },
  ) => void,
  llm_model: string | null,
): Promise<void> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbotv2/ask_moodee_v2`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ conversation_id, content, llm_model }),
      },
    );

    if (!response.ok) {
      throw new Error("Erreur lors du fetch en streaming");
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder();
    let isFirstChunk = true;

    if (reader) {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });

        if (isFirstChunk) {
          try {
            // On cherche le séparateur
            const [jsonPart, textPart] = chunk.split("\n---\n");
            const metadata = JSON.parse(jsonPart);
            if (metadata.type === "ids") {
              onChunkReceived("", metadata);
              isFirstChunk = false;
              // Si on a du texte après le JSON, on le traite
              if (textPart) {
                onChunkReceived(textPart);
              }
              continue;
            }
          } catch {
            // Si ce n'est pas du JSON, c'est un chunk normal
            onChunkReceived(chunk);
          }
        } else {
          onChunkReceived(chunk);
        }
      }
    }
  } catch (error: any) {
    throw new Error(error.message || "Erreur lors de la requête en streaming");
  }
};

export const deleteConversation = async (conversationId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_conversation`, // Ne pas mettre le conversationId dans le chemin
      { params: { conversation_id: conversationId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting  history");
    }
  }
};

export const deleteChat = async (chatId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_chat`, // Ne pas mettre le chatId dans le chemin
      { params: { chat_id: chatId } }, // Utiliser un paramètre de requête
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error deleting chat");
    }
  }
};

export const deleteChatsAfterDate = async (chatId: string) => {
  try {
    const response = await axiosInstance.delete(
      `/chatbotv2/delete_chats_after_date`,
      {
        params: { chat_id: chatId },
      },
    ); // Send data in the body
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error modifying chat");
    }
  }
};

export const toggleChatFavorite = async (chatId: string): Promise<Chat> => {
  try {
    const response = await axiosInstance.put(
      `/chatbotv2/favorite`,
      {},
      { params: { chat_id: chatId } },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error toggling chat favorite status");
    }
  }
};

export const getFavoritesPromptsByUser = async (): Promise<Chat[]> => {
  try {
    const response = await axiosInstance.get("/chatbotv2/favorites");
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching favorite prompts");
    }
  }
};

export const toggleThumbs = async (
  chatId: string,
  thumbs: string,
): Promise<Chat> => {
  try {
    const response = await axiosInstance.put(
      `/chatbotv2/thumbs`,
      {},
      { params: { chat_id: chatId, thumbs } },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error toggling chat thumbs status");
    }
  }
};

export const createFeedback = async (chatId: string, feedback: string) => {
  try {
    const response = await axiosInstance.post(`/chatbotv2/user_feedback`, {
      chat_id: chatId,
      feedback,
    });
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error creating feedback");
    }
  }
};

export const getUserFeedbacks = async () => {
  try {
    const response = await axiosInstance.get(`/chatbotv2/user_feedbacks`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching user feedbacks");
    }
  }
};

export const getFeedbackByChatId = async (chatId: string) => {
  try {
    const response = await axiosInstance.get<Feedback>(
      `/chatbotv2/user_feedback/${chatId}`,
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error fetching user feedbacks");
    }
  }
};

export const addConversationToUser = async (
  conversationId: string,
  userId: string,
) => {
  try {
    const response = await axiosInstance.put(
      `/chatbotv2/add_conversation_to_user`,
      {},
      { params: { conversation_id: conversationId, new_user_id: userId } },
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error adding conversation to user");
    }
  }
};

export const getConversationsByViewer = async () => {
  try {
    const response = await axiosInstance.get<Conversation[]>(
      `/chatbotv2/conversations_by_viewer`,
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      throw error.response.data;
    } else {
      throw new Error("Error getting conversations by viewer");
    }
  }
};

export const exportChatToPdf = async (chatId: string) => {
  try {
    const response = await axiosInstance.post(
      `/chatbotv2/export-pdf`,
      { chat_id: chatId },
      {
        responseType: "blob",
        // Ajouter un timeout pour éviter les attentes infinies
        timeout: 30000,
      },
    );

    // Vérifier si la réponse est vide
    if (!response.data || response.data.size === 0) {
      throw new Error("Le PDF généré est vide");
    }

    // Vérifiez explicitement le type de contenu
    const contentType = response.headers["content-type"];

    if (contentType && !contentType.includes("application/pdf")) {
      // Si le serveur renvoie une erreur au format JSON dans un blob
      if (contentType.includes("application/json")) {
        // Convertir le blob en texte pour voir l'erreur
        const textError = await response.data.text();
        console.error("Erreur JSON reçue:", textError);
        throw new Error(`Erreur serveur: ${textError}`);
      }
      throw new Error(`Type de contenu inattendu: ${contentType}`);
    }

    // Créer le blob avec le type explicite
    const blob = new Blob([response.data], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);

    // Créer un lien temporaire pour télécharger le fichier
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;

    // Récupérer le nom de fichier depuis les headers si disponible
    const contentDisposition = response.headers["content-disposition"];
    let filename = "moodee_export.pdf";

    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/);
      if (filenameMatch && filenameMatch.length > 1) {
        filename = filenameMatch[1];
      }
    }

    a.download = filename;
    document.body.appendChild(a);
    a.click();

    // Nettoyer
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return true;
  } catch (error: any) {
    console.error("Erreur lors de l'exportation PDF:", error);

    if (error.response) {
      // Pour les erreurs HTTP standard
      console.error("Détails de l'erreur HTTP:", {
        status: error.response.status,
        headers: error.response.headers,
        data: error.response.data,
      });
      throw error.response.data;
    } else {
      // Pour les erreurs réseau ou les timeouts
      throw new Error(error.message || "Erreur lors de l'exportation en PDF");
    }
  }
};
