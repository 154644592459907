import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandGoogleMaps,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { postFirstMessage } from "../../lib/askMoodee";
import { useChatStore } from "../../store/useChatStore";
import { useConversationStore } from "../../store/useConversationStore";
import { useUIStore } from "../../store/useUIStore";
import { Focus, SocialMedia } from "../../types";
import { Button } from "../ui/button";
import { MultiSelect } from "../ui/multi-select";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface NewChatFormProps {
  focuses: Focus[];
  socialMedias: SocialMedia[];
}

const NewChatForm = ({ focuses, socialMedias }: NewChatFormProps) => {
  const navigate = useNavigate();

  const {
    newChatForm: { selectedFocus, selectedSocialMedia, isSubmitting },
    setNewChatFocus,
    setNewChatSocialMedia,
    submitNewChatForm,
  } = useConversationStore();

  const { setCurrentConversationChats } = useChatStore();
  const { setShouldShowChat } = useUIStore();
  const { setAnswerLoading } = useChatStore();

  const handleStartChat = async (e: React.FormEvent) => {
    e.preventDefault();

    submitNewChatForm(
      focuses,
      socialMedias,
      navigate,
      setCurrentConversationChats,
      postFirstMessage,
      setShouldShowChat,
      setAnswerLoading,
    );
  };

  return (
    <form
      onSubmit={handleStartChat}
      className="mx-auto mt-36 flex w-11/12 max-w-[750px] flex-col gap-5 rounded-3xl border border-gray-200 bg-white p-16 shadow-md"
    >
      <h3 className="mx-auto text-2xl font-semibold">
        Choisis ton produit et tes media
      </h3>

      <Select onValueChange={setNewChatFocus} value={selectedFocus}>
        <SelectTrigger className="flex h-12 w-full items-center justify-between rounded-full border border-gray-200 bg-inherit px-6 text-sm capitalize text-gray-500 shadow-sm hover:bg-inherit">
          <SelectValue placeholder="Produit" />
        </SelectTrigger>
        <SelectContent>
          {focuses
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((focus) => (
              <SelectItem
                key={focus.focus_id}
                value={focus.focus_id}
                className="capitalize focus:bg-green-light"
              >
                {focus.name}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>

      <MultiSelect
        options={socialMedias
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((social) => ({
            label: social.name,
            value: social.social_media_id,
            icon: (() => {
              if (social.formatted_name === "tiktok") return IconBrandTiktok;
              if (social.formatted_name === "google_news")
                return IconBrandGoogle;
              if (social.formatted_name === "instagram")
                return IconBrandInstagram;
              if (social.formatted_name === "amazon_reviews")
                return IconBrandAmazon;
              if (social.formatted_name === "linkedin")
                return IconBrandLinkedin;
              if (social.formatted_name === "x") return IconBrandX;
              if (social.formatted_name === "facebook")
                return IconBrandFacebook;
              if (social.formatted_name === "google_maps_reviews")
                return IconBrandGoogleMaps;
              return IconTool;
            })(),
          }))}
        onValueChange={setNewChatSocialMedia}
        defaultValue={selectedSocialMedia}
        placeholder="Media"
        animation={2}
        maxCount={3}
        className="h-12 p-0 px-6"
        base="orange"
        hover="orange"
        selected="orange"
      />

      <Button
        type="submit"
        className="mx-auto w-fit rounded-lg px-5"
        hover="jumping"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <div className="flex items-center gap-2">
            <div className="h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
            <span>Création en cours...</span>
          </div>
        ) : (
          "Démarrer la conversation"
        )}
      </Button>
    </form>
  );
};

export default NewChatForm;
