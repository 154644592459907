import { PopoverClose } from "@radix-ui/react-popover";
import {
  ArrowUp,
  ChartColumnBigIcon,
  SquareTerminal,
  Star,
  XIcon,
} from "lucide-react";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { CHATBOT_MODELS, CHATBOT_QUESTIONS } from "../../lib/constants";
import { cn } from "../../lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { Textarea } from "../ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
// Importer les stores
import { useUser } from "../../context/UserContext";
import { useChatStore } from "../../store/useChatStore";
import { useConversationStore } from "../../store/useConversationStore";
import { useUIStore } from "../../store/useUIStore";

interface ChatInputProps {
  textareaRef: React.RefObject<HTMLTextAreaElement>;
}

const ChatInput = ({ textareaRef }: ChatInputProps) => {
  const { user } = useUser();
  const isUserSuperadmin = user?.category === "SUPERADMIN";

  const {
    chatContent,
    setChatContent,
    answerLoading,
    modifiedChatId,
    setModifiedChatId,
    sendMessage,
    currentConversationChats,
  } = useChatStore();

  const {
    favoritePrompts,
    toggleFavorite,
    llmModel,
    setLlmModel,
    updateConversationLastMessage,
  } = useConversationStore();

  const { adjustTextareaHeight, adjustCursor } = useUIStore();

  const selectedConversation = useConversationStore((state) => {
    if (!currentConversationChats?.[0]?.conversation_id) return null;

    const convId = currentConversationChats[0].conversation_id;
    return (
      state.conversations.find((c) => c.conversation_id === convId) ||
      state.viewerConversations.find((c) => c.conversation_id === convId)
    );
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (!currentConversationChats || !chatContent || !selectedConversation)
        return;

      if (modifiedChatId) {
        useChatStore.getState().deleteChatsAfter(modifiedChatId);
        setModifiedChatId(null);
      }

      const conversationId = currentConversationChats[0].conversation_id;

      updateConversationLastMessage(conversationId);

      const modelToUse = isUserSuperadmin ? llmModel : null;
      sendMessage(conversationId, chatContent, modelToUse);

      setTimeout(() => adjustTextareaHeight(), 0);
    },
    [
      currentConversationChats,
      chatContent,
      selectedConversation,
      modifiedChatId,
      isUserSuperadmin,
      llmModel,
      adjustTextareaHeight,
      setModifiedChatId,
      updateConversationLastMessage,
      sendMessage,
    ],
  );

  const handleSelectPrompt = useCallback(
    (prompt: string) => {
      setChatContent(prompt);
      setTimeout(() => {
        adjustTextareaHeight();
        adjustCursor(prompt.length);
      }, 0);
    },
    [setChatContent, adjustTextareaHeight, adjustCursor],
  );

  if (!selectedConversation) return null;

  return (
    <>
      <form
        className={cn(
          answerLoading && "pointer-events-none opacity-50",
          "mx-auto flex max-h-64 w-11/12 flex-col rounded-2xl border border-gray-200 p-2.5 shadow-md xl:w-10/12 2xl:w-9/12",
        )}
        onSubmit={handleSubmit}
      >
        {modifiedChatId && (
          <div className="mb-1 flex rounded-lg bg-gray-200 p-1">
            <p className="text-sm">Modification du message</p>
            <button
              onClick={() => {
                setModifiedChatId(null);
                setChatContent("");
              }}
              className="ml-auto"
            >
              <XIcon className="size-5" />
            </button>
          </div>
        )}

        <div className="gap-z flex items-start">
          <Textarea
            ref={textareaRef}
            placeholder="Message à Moodee"
            className="max-h-48 resize-none border-none px-1 py-1 focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
            value={chatContent}
            onChange={(e) => {
              setChatContent(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Empêche le saut de ligne
                handleSubmit(e);
              }
            }}
          />
          <button
            type="submit"
            className={cn(
              chatContent.length === 0 ? "opacity-0" : "opacity-100",
              "h-fit rounded-xl bg-blue p-1.5 text-white transition-opacity",
            )}
            disabled={answerLoading || chatContent.length === 0}
          >
            <ArrowUp className="size-5" />
          </button>
        </div>

        <div className="flex items-center justify-start gap-2">
          <Popover>
            <PopoverTrigger className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-1.5 text-sm hover:bg-gray-200">
              Prompts
              <SquareTerminal className="size-5" />
            </PopoverTrigger>
            <PopoverContent align="start">
              <Tabs
                defaultValue={
                  favoritePrompts.length > 0 ? "favorites" : "moodee"
                }
                className="h-96 w-[350px] p-2"
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="moodee">Suggestions Moodee</TabsTrigger>
                  <TabsTrigger value="favorites">Favoris</TabsTrigger>
                </TabsList>
                <TabsContent value="moodee">
                  <div className="flex h-80 flex-col space-y-1 overflow-y-auto">
                    {CHATBOT_QUESTIONS.map((question, index) => (
                      <PopoverClose key={index}>
                        <button
                          className="w-full rounded-lg border border-gray-200 px-2.5 py-1.5 text-center text-sm text-dark shadow-sm hover:bg-gray-200"
                          onClick={() => {
                            handleSelectPrompt(question.prompt);
                          }}
                        >
                          {question.label}
                        </button>
                      </PopoverClose>
                    ))}
                  </div>
                </TabsContent>
                <TabsContent value="favorites">
                  <div className="flex h-80 flex-col space-y-1 overflow-y-auto">
                    {favoritePrompts.map((chat, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between gap-2"
                      >
                        <PopoverClose className="w-full">
                          <button
                            className="w-full rounded-lg border border-gray-200 px-2.5 py-1.5 text-center text-sm text-dark shadow-sm hover:bg-gray-200"
                            onClick={() => {
                              handleSelectPrompt(chat.content);
                            }}
                          >
                            {chat.favorite_summary}
                          </button>
                        </PopoverClose>
                        <button onClick={() => toggleFavorite(chat.chat_id)}>
                          <Star
                            className={cn(
                              chat.favorite === true &&
                                "fill-yellow-400 text-yellow-400",
                              "size-4",
                            )}
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                </TabsContent>
              </Tabs>
            </PopoverContent>
          </Popover>

          <TooltipProvider>
            <Tooltip delayDuration={200}>
              <TooltipTrigger>
                <Link
                  to={`/focus/${selectedConversation.focus_ids[0] || ""}`}
                  state={{ focusId: selectedConversation.focus_ids[0] }}
                  className="flex h-9 items-center gap-2 rounded-lg border border-gray-200 px-4 text-sm hover:bg-gray-200"
                >
                  <ChartColumnBigIcon className="size-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                Statistiques de {selectedConversation.focus_names.join(", ")}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          {isUserSuperadmin && (
            <Select
              onValueChange={(value) => setLlmModel(value)}
              defaultValue={CHATBOT_MODELS[0].value}
            >
              <SelectTrigger className="flex h-9 w-36 items-center rounded-lg border border-gray-200 bg-inherit px-4 py-1.5 text-sm text-dark hover:bg-inherit">
                <SelectValue placeholder="Choisir un focus existant" />
              </SelectTrigger>
              <SelectContent className="w-36">
                {CHATBOT_MODELS.map((focus) => (
                  <SelectItem
                    key={focus.value}
                    value={focus.value}
                    className="focus:bg-gray-200"
                  >
                    {focus.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
      </form>
      <div className="-mb-4 mt-2 text-center text-[10px] italic text-gray-600">
        Moodee peut faire des erreurs. Envisagez de vérifier les informations
        importantes.
      </div>
    </>
  );
};

export default React.memo(ChatInput);
