import { Scraping, ScrapingData } from "../types";
import axiosInstance from "./axiosInstance";

export const createNewScraping = async (
  data: ScrapingData,
): Promise<Scraping> => {
  try {
    const response = await axiosInstance.post("/scraping/", data);
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(
        error.response.data.detail || "Failed to create scraping",
      );
    } else {
      throw new Error("Failed to create scraping");
    }
  }
};

export const getScrapingsByFocus = async (
  focus_id: string,
): Promise<Scraping[]> => {
  try {
    const response = await axiosInstance.get<Scraping[]>(
      `/scraping/${focus_id}`,
    );
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw new Error(error.response.data.detail || "Failed to fetch focus");
    } else {
      throw new Error("Failed to fetch focus");
    }
  }
};
