import { motion } from "framer-motion";
import {
  ChartPieIcon,
  LogOut,
  MessageCircle,
  Settings,
  ShoppingBag,
} from "lucide-react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logout } from "../lib/auth"; // Import the logout function
import {
  LogoutButton,
  Sidebar,
  SidebarBody,
  SidebarLink,
} from "./ui/sidebar-ui";

export function SidebarNavigation() {
  const location = useLocation(); // Get the current URL

  const links = [
    {
      label: "Dashboard",
      href: "/dashboard",
      icon: (
        <ChartPieIcon
          strokeWidth={3}
          className="h-6 w-6 flex-shrink-0 text-blue"
        />
      ),
    },
    {
      label: "Focus",
      href: "/focus",
      icon: (
        <ShoppingBag
          strokeWidth={3}
          className="h-6 w-6 flex-shrink-0 text-blue"
        />
      ),
    },
    {
      label: "Conversations",
      href: "/chat",
      icon: (
        <MessageCircle
          strokeWidth={3}
          className="h-6 w-6 flex-shrink-0 text-blue"
        />
      ),
    },
    {
      label: "Paramètres",
      href: "/configuration",
      icon: (
        <Settings strokeWidth={3} className="h-6 w-6 flex-shrink-0 text-blue" />
      ),
    },
  ];

  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    logout(); // Call the logout function
  };

  return (
    <Sidebar open={open} setOpen={setOpen}>
      <SidebarBody className="justify-between gap-5">
        <div className="flex flex-col overflow-y-auto overflow-x-hidden">
          <Logo />
        </div>
        <div className="flex flex-col gap-6">
          {links.map((link, idx) => (
            <SidebarLink
              key={idx}
              link={link}
              active={location.pathname.startsWith(link.href)}
            />
          ))}
        </div>
        <LogoutButton
          link={{
            label: "Déconnexion",
            icon: (
              <LogOut
                strokeWidth={3}
                className="size-6 flex-shrink-0 text-blue"
              />
            ),
          }}
          onClick={handleLogout} // Add onClick handler for logout
        />
      </SidebarBody>
    </Sidebar>
  );
}

export const Logo = () => {
  return (
    <Link
      to="/" // Redirect to the home route
      className="group/sidebar ml-4 mt-4 flex items-center justify-start gap-3"
    >
      <img src="/moodee-v1.png" className="size-9" alt="logo" />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="whitespace-pre text-2xl font-medium text-blue"
      >
        Moodee
      </motion.span>
    </Link>
  );
};
