import {
  Check,
  Copy,
  FileDown,
  Pencil,
  Star,
  ThumbsDown,
  ThumbsUp,
  Trash,
} from "lucide-react";
import { memo, useCallback, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { cn } from "../../lib/utils";
import { Chat, Feedback } from "../../types";
import AvatarUser from "../Avatar";
import SecureImage from "../SecureImage";
import { Button } from "../ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Textarea } from "../ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface ChatMessageProps {
  chat: Chat;
  user: any;
  isLastMessage: boolean;
  answerLoading: boolean;
  answerFinished: boolean;
  moodeeWaitMessage: string;
  isFavorite?: boolean;
  onThumbsUp: (chatId: string) => void;
  onThumbsDown: (chatId: string) => void;
  onCreateFeedback: (chatId: string, feedback: string) => void;
  onToggleFavorite: (chatId: string) => Promise<void>;
  onEdit: (content: string, chatId: string) => void;
  onDelete: (chatId: string) => void;
  onExportPdf: (chatId: string) => Promise<void>;
  getFeedback: (chatId: string) => Promise<Feedback | null>;
}

const MessageContent = memo(
  ({
    content,
    author,
    components,
  }: {
    content: string;
    author: string;
    components: any;
  }) => {
    return (
      <ReactMarkdown
        className={cn(
          author !== "Moodee" &&
            "rounded-2xl border border-gray-200 px-4 py-3 shadow-md",
          "chat-response",
        )}
        children={content}
        remarkPlugins={[remarkGfm]}
        components={components}
      />
    );
  },
);
MessageContent.displayName = "MessageContent";

const FeedbackForm = memo(
  ({
    onSubmit,
  }: {
    onSubmit: (withComment: boolean, feedback: string) => void;
  }) => {
    const [localFeedback, setLocalFeedback] = useState("");

    const handleSubmit = useCallback(
      (withComment: boolean) => {
        onSubmit(withComment, localFeedback);
        setLocalFeedback("");
      },
      [localFeedback, onSubmit],
    );

    return (
      <div className="grid gap-2">
        <h4 className="font-medium leading-none">
          La réponse ne vous convient pas ? 🤔 Dites-nous pourquoi !
        </h4>
        <p className="text-sm text-gray-500">
          Votre commentaire nous aide à améliorer nos réponses
        </p>
        <Textarea
          placeholder="Votre avis..."
          value={localFeedback}
          onChange={(e) => setLocalFeedback(e.target.value)}
          className="min-h-24 resize-none rounded-xl border border-gray-200 focus:outline-none focus:ring-0 focus-visible:outline-none focus-visible:ring-0"
        />
        <div className="flex gap-2">
          <Button
            onClick={() => handleSubmit(false)}
            variant="outline"
            className="flex-1"
          >
            Sans commentaire
          </Button>
          <Button onClick={() => handleSubmit(true)} className="flex-1">
            Envoyer votre commentaire
          </Button>
        </div>
      </div>
    );
  },
);
FeedbackForm.displayName = "FeedbackForm";

const ChatMessage = ({
  chat,
  user,
  isLastMessage,
  answerLoading,
  answerFinished,
  moodeeWaitMessage,
  isFavorite,
  onThumbsUp,
  onThumbsDown,
  onCreateFeedback,
  onToggleFavorite,
  onEdit,
  onDelete,
  onExportPdf,
  getFeedback,
}: ChatMessageProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isTogglingFavorite, setIsTogglingFavorite] = useState(false);

  const isFeedbackPopoverOpen = openPopoverId === chat.chat_id;

  const handleFeedbackSubmit = useCallback(
    (withComment: boolean, feedback: string) => {
      onThumbsDown(chat.chat_id);
      if (withComment && feedback.trim()) {
        onCreateFeedback(chat.chat_id, feedback);
      }
      setOpenPopoverId(null);
    },
    [chat.chat_id, onThumbsDown, onCreateFeedback],
  );

  const handleFeedbackPopoverOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setOpenPopoverId(null);
    }
  }, []);

  const handleThumbsDownClick = useCallback(() => {
    if (chat.thumbs === "DOWN") {
      onThumbsDown(chat.chat_id);
    } else {
      setOpenPopoverId(chat.chat_id);
    }
  }, [chat.thumbs, chat.chat_id, onThumbsDown]);

  const handleLoadFeedback = useCallback(async () => {
    setIsLoadingFeedback(true);
    try {
      const data = await getFeedback(chat.chat_id);
      setFeedback(data);
    } catch (error) {
      setFeedback(null);
    } finally {
      setIsLoadingFeedback(false);
    }
  }, [chat.chat_id, getFeedback]);

  const handleExportPdf = useCallback(async () => {
    if (isExporting) return;
    setIsExporting(true);
    try {
      await onExportPdf(chat.chat_id);
    } catch (error) {
      console.error("Erreur lors de l'exportation PDF:", error);
    } finally {
      setIsExporting(false);
    }
  }, [chat.chat_id, isExporting, onExportPdf]);

  const handleToggleFavorite = useCallback(async () => {
    if (isTogglingFavorite) return;
    setIsTogglingFavorite(true);
    try {
      await onToggleFavorite(chat.chat_id);
    } catch (error) {
      console.error("Erreur lors du toggle du favori:", error);
    } finally {
      setIsTogglingFavorite(false);
    }
  }, [chat.chat_id, isTogglingFavorite, onToggleFavorite]);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(chat.content);
    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 1000);
  }, [chat.content]);

  const handleEdit = useCallback(() => {
    onEdit(chat.content, chat.chat_id);
  }, [chat.content, chat.chat_id, onEdit]);

  const handleDelete = useCallback(() => {
    onDelete(chat.chat_id);
  }, [chat.chat_id, onDelete]);

  const handleThumbsUp = useCallback(() => {
    onThumbsUp(chat.chat_id);
  }, [chat.chat_id, onThumbsUp]);

  const markdownComponents = useMemo(
    () => ({
      a: ({ node, children, ...props }: any) => (
        <a {...props} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
      img: ({ node, ...props }: any) => {
        const src = props.src || "";
        if (src.includes("chatbot_images/")) {
          const imageId = src.split("/").pop();
          if (!imageId) return null;
          return <SecureImage imageId={imageId} />;
        }
        return <img {...props} alt="moodee" className="max-w-full rounded" />;
      },
    }),
    [],
  );

  const isFavoriteMessage =
    isFavorite !== undefined ? isFavorite : chat.favorite;

  return (
    <div className="group">
      <div
        className={cn(
          chat.author !== "Moodee" ? "ml-auto" : "mr-auto",
          "flex w-fit max-w-[95%] flex-col",
        )}
      >
        <div
          className={cn(
            chat.author !== "Moodee" && "flex-row-reverse",
            "flex items-start gap-3",
          )}
        >
          {chat.author === "Moodee" ? (
            <img
              src="/moodee-v1.png"
              alt="logo"
              className="size-7 rounded-full"
            />
          ) : (
            <AvatarUser user={user} className="size-7" />
          )}

          {answerLoading && isLastMessage && chat.author === "Moodee" && (
            <span className="animate-pulse transition">
              {moodeeWaitMessage}
            </span>
          )}

          {!answerLoading || chat.author !== "Moodee" || !isLastMessage ? (
            <MessageContent
              content={chat.content}
              author={chat.author}
              components={markdownComponents}
            />
          ) : null}
        </div>

        {answerFinished && (
          <div
            className={cn(
              chat.author === "Moodee"
                ? "ml-10 justify-start"
                : "mr-12 justify-end",
              "mt-2 flex gap-3 opacity-0 group-hover:opacity-60",
            )}
          >
            {chat.author !== "Moodee" && (
              <>
                <button
                  onClick={handleToggleFavorite}
                  disabled={isTogglingFavorite}
                >
                  {isTogglingFavorite ? (
                    <div className="size-3.5 animate-spin rounded-full border border-gray-300 border-t-transparent"></div>
                  ) : (
                    <Star
                      className={cn(
                        isFavoriteMessage && "fill-yellow-400 text-yellow-400",
                        "size-3.5",
                      )}
                    />
                  )}
                </button>
                <button onClick={handleEdit}>
                  <Pencil className="size-3.5" />
                </button>
              </>
            )}
            {chat.author === "Moodee" && (
              <>
                <button onClick={handleThumbsUp}>
                  <ThumbsUp
                    className={cn(
                      chat.thumbs === "UP" && "fill-green text-green",
                      "size-3.5",
                    )}
                  />
                </button>

                {chat.thumbs === "DOWN" ? (
                  <TooltipProvider delayDuration={300}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <button
                          onClick={() => onThumbsDown(chat.chat_id)}
                          onMouseEnter={handleLoadFeedback}
                        >
                          <ThumbsDown className="size-3.5 fill-orange text-orange" />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs rounded-xl">
                        {isLoadingFeedback ? (
                          <p className="text-sm text-gray-500">Chargement...</p>
                        ) : feedback?.feedback ? (
                          <>
                            <h3 className="mb-1 text-center font-bold">
                              Votre commentaire
                            </h3>
                            <p>{feedback?.feedback}</p>
                          </>
                        ) : (
                          <p className="text-sm text-gray-500">
                            Aucun commentaire
                          </p>
                        )}
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  <Popover
                    open={isFeedbackPopoverOpen}
                    onOpenChange={handleFeedbackPopoverOpenChange}
                  >
                    <PopoverTrigger asChild>
                      <button onClick={handleThumbsDownClick}>
                        <ThumbsDown
                          className={cn(
                            chat.thumbs === "DOWN" && "fill-orange text-orange",
                            "size-3.5",
                          )}
                        />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="w-fit p-4">
                      <FeedbackForm onSubmit={handleFeedbackSubmit} />
                    </PopoverContent>
                  </Popover>
                )}

                <TooltipProvider delayDuration={300}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <button onClick={handleExportPdf} disabled={isExporting}>
                        {isExporting ? (
                          <div className="border-primary h-3.5 w-3.5 animate-spin rounded-full border-2 border-t-transparent"></div>
                        ) : (
                          <FileDown className="size-3.5" />
                        )}
                      </button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Exporter en PDF</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </>
            )}
            <button onClick={handleCopy}>
              {hasCopied ? (
                <div className="flex items-center gap-1">
                  <Check className="size-4" />
                </div>
              ) : (
                <Copy className="size-3.5" />
              )}
            </button>
            <button onClick={handleDelete}>
              <Trash className="size-3.5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ChatMessage);
