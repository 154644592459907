// hooks/useUIRefs.ts
import { useRef, useEffect } from "react";
import { useUIStore } from "../store/useUIStore";

export const useUIRefs = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    useUIStore.getState().setMessagesEndRef(messagesEndRef);
    useUIStore.getState().setTextareaRef(textareaRef);
  }, []);

  return {
    messagesEndRef,
    textareaRef,
  };
};
