import {
  IconBrandAmazon,
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTiktok,
  IconBrandX,
  IconTool,
} from "@tabler/icons-react";
import { ChevronDownIcon, Search } from "lucide-react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { cn } from "../lib/utils";
import AvatarUser from "./Avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "./ui/dropdown";
import { Input } from "./ui/input";

type HeaderProps = {
  title: string;
  searchQuery?: string;
  setSearchQuery?: React.Dispatch<React.SetStateAction<string>>;
  isDashboard?: boolean;
  socialMediasDetail?: string[];
};

export default function Header({
  title,
  searchQuery,
  setSearchQuery,
  isDashboard,
  socialMediasDetail,
}: HeaderProps) {
  const { user } = useUser();

  return (
    <div className="flex items-center justify-between px-4 py-8 sm:px-8 lg:px-12">
      <div className="flex items-center gap-12">
        <h1
          className={cn(
            isDashboard ? "text-white" : "text-blue",
            "w-fit text-4xl font-semibold",
          )}
        >
          {title}
        </h1>
        {socialMediasDetail && socialMediasDetail?.length > 0 && (
          <div className="flex h-full items-center gap-2 rounded-xl bg-blue-light p-3">
            {socialMediasDetail.map((social) => (
              <div key={social}>
                {social === "tiktok" && <IconBrandTiktok size={32} />}
                {social === "instagram" && <IconBrandInstagram size={32} />}
                {social === "google_news" && <IconBrandGoogle size={32} />}
                {social === "google_maps_reviews" && (
                  <IconBrandGoogle size={32} />
                )}
                {social === "linkedin" && <IconBrandLinkedin size={32} />}
                {social === "amazon_reviews" && <IconBrandAmazon size={32} />}
                {social === "facebook" && <IconBrandFacebook size={32} />}
                {social === "x" && <IconBrandX size={32} />}
                {social === "custom" && <IconTool size={32} />}
              </div>
            ))}
          </div>
        )}
      </div>
      {setSearchQuery && (
        <div
          className={cn(
            "hidden w-96 items-center rounded-xl bg-gray-100 px-4 sm:flex",
          )}
        >
          <Search className="text-blue" />
          <Input
            placeholder="Rechercher..."
            className="w-full rounded-full border-none text-dark focus:outline-none focus:ring-0 focus-visible:ring-0"
            value={searchQuery}
            onChange={setSearchQuery && ((e) => setSearchQuery(e.target.value))}
          />
        </div>
      )}

      <div className="flex items-center gap-20">
        {/* <Select>
          <SelectTrigger className="w-fit capitalize text-dark">
            <SelectValue placeholder="Langue" />
          </SelectTrigger>
          <SelectContent>
            {languages.map((language) => (
              <SelectItem
                key={language.language_id}
                value={language.language_id}
              >
                {language.language_name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select> */}

        <DropdownMenu>
          <DropdownMenuTrigger className="focus:ring-none flex items-center gap-2 focus:border-none">
            <AvatarUser user={user} className="size-10" />
            <ChevronDownIcon className="size-6" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>Mon compte</DropdownMenuLabel>
            <DropdownMenuItem>
              <Link to="/profile">Informations personnelles</Link>
            </DropdownMenuItem>
            <DropdownMenuItem disabled>Notifications</DropdownMenuItem>
            <DropdownMenuItem disabled>Paiement</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}
