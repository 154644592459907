// useConversationStore.ts
import { create } from "zustand";
import {
  deleteConversation,
  getConversationHistory,
  getConversationsByUserPaginated,
  getConversationsByViewer,
  getFavoritesPromptsByUser,
  startNewChat,
  toggleChatFavorite,
} from "../lib/askMoodee";
import { calculateOptimalItems } from "../lib/utils";
import { Chat, Conversation, Focus, SocialMedia } from "../types";

type ConversationState = {
  conversations: Conversation[];
  viewerConversations: Conversation[];
  filteredConversations: Conversation[];
  page: number;
  itemsPerPage: number;
  hasMore: boolean;
  isConversationsLoading: boolean;
  isLoadingMore: boolean;
  error: string | null;

  searchConversation: string;
  favoritePrompts: Chat[];
  llmModel: string | null;
  newChatForm: {
    selectedFocus: string;
    selectedSocialMedia: string[];
    isSubmitting: boolean;
    formError: string | null;
  };

  containerRef: React.RefObject<HTMLDivElement> | null;
  observerRef: React.RefObject<HTMLDivElement> | null;

  setConversations: (conversations: Conversation[]) => void;
  setViewerConversations: (conversations: Conversation[]) => void;
  setSearchConversation: (search: string) => void;
  setFavoritePrompts: (prompts: Chat[]) => void;
  setLlmModel: (model: string | null) => void;
  setItemsPerPage: (count: number) => void;
  setError: (error: string | null) => void;

  setNewChatFocus: (focus: string) => void;
  setNewChatSocialMedia: (socialMedia: string[]) => void;
  resetNewChatForm: () => void;
  setNewChatFormError: (error: string | null) => void;

  setContainerRef: (ref: React.RefObject<HTMLDivElement>) => void;
  setObserverRef: (ref: React.RefObject<HTMLDivElement>) => void;

  loadInitialConversations: () => Promise<void>;
  loadMoreConversations: () => Promise<void>;
  loadViewerConversations: () => Promise<void>;
  loadFavoritePrompts: () => Promise<Chat[]>;
  loadAllInitialData: () => Promise<void>;

  measureContainerDimensions: () => void;

  submitNewChatForm: (
    focuses: Focus[],
    socialMedias: SocialMedia[],
    navigate: (path: string) => void,
    setCurrentConversationChats: (chats: Chat[] | null) => void,
    postFirstMessage: (conversationId: string) => Promise<Chat>,
    setShouldShowChat: (shouldShow: boolean) => void,
    setAnswerLoading: (loading: boolean) => void,
  ) => Promise<void>;

  startNewConversation: (
    focusId: string,
    socialMediaIds: string[],
    focuses: Focus[],
    socialMedias: SocialMedia[],
  ) => Promise<Conversation>;
  loadConversationHistory: (conversationId: string) => Promise<Chat[]>;
  removeConversation: (conversationId: string) => Promise<void>;
  toggleFavorite: (chatId: string) => Promise<Chat[]>;
  updateConversationLastMessage: (conversationId: string) => void;
};

export const useConversationStore = create<ConversationState>((set, get) => ({
  conversations: [],
  viewerConversations: [],
  filteredConversations: [],
  favoritePrompts: [],
  page: 1,
  itemsPerPage: 20,
  hasMore: true,
  isConversationsLoading: false,
  isLoadingMore: false,
  error: null,

  searchConversation: "",
  llmModel: "gpt-4o-mini",

  newChatForm: {
    selectedFocus: "",
    selectedSocialMedia: [],
    isSubmitting: false,
    formError: null,
  },

  containerRef: null,
  observerRef: null,

  setConversations: (conversations) => {
    set({
      conversations,
      filteredConversations: filterConversations(
        conversations,
        get().searchConversation,
      ),
    });
  },
  setViewerConversations: (viewerConversations) => set({ viewerConversations }),
  setSearchConversation: (search) => {
    set({
      searchConversation: search,
      filteredConversations: filterConversations(get().conversations, search),
    });
  },
  setFavoritePrompts: (prompts) => set({ favoritePrompts: prompts }),
  setLlmModel: (model) => set({ llmModel: model }),
  setItemsPerPage: (count) => set({ itemsPerPage: count }),
  setError: (error) => set({ error }),

  setNewChatFocus: (focus) =>
    set((state) => ({
      newChatForm: {
        ...state.newChatForm,
        selectedFocus: focus,
        formError: null,
      },
    })),
  setNewChatSocialMedia: (socialMedia) =>
    set((state) => ({
      newChatForm: {
        ...state.newChatForm,
        selectedSocialMedia: socialMedia,
        formError: null,
      },
    })),
  resetNewChatForm: () =>
    set((state) => ({
      newChatForm: {
        selectedFocus: "",
        selectedSocialMedia: [],
        isSubmitting: false,
        formError: null,
      },
    })),
  setNewChatFormError: (error) =>
    set((state) => ({
      newChatForm: {
        ...state.newChatForm,
        formError: error,
      },
    })),

  setContainerRef: (ref) => {
    set({ containerRef: ref });

    setTimeout(() => get().measureContainerDimensions(), 0);

    window.addEventListener("resize", get().measureContainerDimensions);

    return () =>
      window.removeEventListener("resize", get().measureContainerDimensions);
  },

  setObserverRef: (ref) => {
    set({ observerRef: ref });

    const observer = ref.current
      ? new IntersectionObserver(
          (entries) => {
            if (
              entries[0].isIntersecting &&
              get().hasMore &&
              !get().isLoadingMore &&
              !get().isConversationsLoading
            ) {
              get().loadMoreConversations();
            }
          },
          { threshold: 0.5 },
        )
      : null;

    if (ref.current && observer) {
      observer.observe(ref.current);
    }
    return () => observer?.disconnect();
  },

  measureContainerDimensions: () => {
    const { containerRef } = get();
    if (!containerRef?.current) return;

    const { clientHeight } = containerRef.current;

    if (clientHeight > 0) {
      const newItemsPerPage = Math.min(
        Math.max(calculateOptimalItems(clientHeight), 5),
        30,
      );

      const currentItemsPerPage = get().itemsPerPage;
      if (newItemsPerPage !== currentItemsPerPage) {
        set({ itemsPerPage: newItemsPerPage });
        if (get().conversations.length > 0) {
          get().loadInitialConversations();
        }
      }
    }
  },

  loadAllInitialData: async () => {
    try {
      await Promise.all([
        get().loadInitialConversations(),
        get().loadViewerConversations(),
        get().loadFavoritePrompts(),
      ]);
    } catch (err: any) {
      console.error("Échec du chargement des données initiales", err);
      set({
        error: err.message || "Échec du chargement des données initiales.",
      });
    }
  },

  loadInitialConversations: async () => {
    try {
      set({ isConversationsLoading: true, error: null, page: 1 });

      const result = await getConversationsByUserPaginated(
        1,
        get().itemsPerPage,
      );

      set({
        conversations: result.conversations,
        filteredConversations: filterConversations(
          result.conversations,
          get().searchConversation,
        ),
        hasMore: result.has_more,
        isConversationsLoading: false,
      });
    } catch (err: any) {
      console.error("Échec du chargement des conversations", err);
      set({
        error: err.message || "Échec du chargement des conversations.",
        isConversationsLoading: false,
      });
    }
  },

  loadMoreConversations: async () => {
    const {
      hasMore,
      isLoadingMore,
      isConversationsLoading,
      page,
      itemsPerPage,
    } = get();

    if (!hasMore || isLoadingMore || isConversationsLoading) return;

    try {
      set({ isLoadingMore: true, error: null });

      const nextPage = page + 1;
      const result = await getConversationsByUserPaginated(
        nextPage,
        itemsPerPage,
      );

      set((state) => ({
        conversations: [...state.conversations, ...result.conversations],
        filteredConversations: filterConversations(
          [...state.conversations, ...result.conversations],
          state.searchConversation,
        ),
        hasMore: result.has_more,
        page: nextPage,
        isLoadingMore: false,
      }));
    } catch (err: any) {
      console.error(
        "Échec du chargement de conversations supplémentaires",
        err,
      );
      set({
        error:
          err.message ||
          "Échec du chargement de conversations supplémentaires.",
        isLoadingMore: false,
      });
    }
  },

  loadViewerConversations: async () => {
    try {
      const result = await getConversationsByViewer();
      set({ viewerConversations: result });
    } catch (err: any) {
      console.error("Échec du chargement des conversations assignées", err);
      set({
        error:
          err.message || "Échec du chargement des conversations assignées.",
      });
    }
  },

  loadFavoritePrompts: async () => {
    try {
      const result = await getFavoritesPromptsByUser();
      set({ favoritePrompts: result });
      return result; // Retourne les résultats pour un usage direct
    } catch (err: any) {
      console.error("Échec du chargement des favoris", err);
      set({
        error: err.message || "Échec du chargement des favoris.",
      });
      return []; // Retourne un tableau vide en cas d'erreur
    }
  },

  // Soumission du formulaire
  submitNewChatForm: async (
    focuses,
    socialMedias,
    navigate,
    setCurrentConversationChats,
    postFirstMessage,
    setShouldShowChat,
    setAnswerLoading,
  ) => {
    const { selectedFocus, selectedSocialMedia } = get().newChatForm;

    // Validation du formulaire
    if (!selectedFocus || selectedSocialMedia.length === 0) {
      set((state) => ({
        newChatForm: {
          ...state.newChatForm,
          formError:
            "Veuillez sélectionner un focus et une plateforme de médias sociaux.",
        },
      }));
      return;
    }

    try {
      set((state) => ({
        newChatForm: {
          ...state.newChatForm,
          isSubmitting: true,
          formError: null,
        },
      }));

      const newConversation = await get().startNewConversation(
        selectedFocus,
        selectedSocialMedia,
        focuses,
        socialMedias,
      );

      const firstMessage = await postFirstMessage(
        newConversation.conversation_id,
      );
      setCurrentConversationChats([firstMessage]);

      navigate(`?conversation_id=${newConversation.conversation_id}`);
      setShouldShowChat(true);

      set((state) => ({
        newChatForm: {
          selectedFocus: "",
          selectedSocialMedia: [],
          isSubmitting: false,
          formError: null,
        },
      }));
    } catch (error: any) {
      setShouldShowChat(false);
      set((state) => ({
        newChatForm: {
          ...state.newChatForm,
          isSubmitting: false,
          formError: "Échec de la création d'une nouvelle conversation.",
        },
      }));
      setAnswerLoading(false);
    }
  },

  startNewConversation: async (
    focusId,
    socialMediaIds,
    focuses,
    socialMedias,
  ) => {
    const conversationData = {
      focus_ids: [focusId],
      social_media_ids: socialMediaIds,
    };

    try {
      const newConversation = await startNewChat(conversationData);

      const completeConversation = {
        ...newConversation,
        focus_names: focuses
          .filter((f) => focusId === f.focus_id)
          .map((f) => f.name),
        social_media_formatted_names: socialMedias
          .filter((sm) => socialMediaIds.includes(sm.social_media_id))
          .map((sm) => sm.formatted_name),
      };

      set((state) => ({
        conversations: [...state.conversations, completeConversation],
        filteredConversations: filterConversations(
          [...state.conversations, completeConversation],
          state.searchConversation,
        ),
      }));

      return completeConversation;
    } catch (error) {
      console.error(
        "Erreur lors de la création d'une nouvelle conversation",
        error,
      );
      throw error;
    }
  },

  loadConversationHistory: async (conversationId) => {
    try {
      const history = await getConversationHistory(conversationId);
      return history;
    } catch (error) {
      console.error("Erreur lors du chargement de l'historique", error);
      throw error;
    }
  },

  removeConversation: async (conversationId) => {
    try {
      await deleteConversation(conversationId);
      set((state) => ({
        conversations: state.conversations.filter(
          (conv) => conv.conversation_id !== conversationId,
        ),
        filteredConversations: state.filteredConversations.filter(
          (conv) => conv.conversation_id !== conversationId,
        ),
      }));
    } catch (error) {
      console.error("Erreur lors de la suppression de la conversation", error);
      throw error;
    }
  },

  toggleFavorite: async (chatId) => {
    try {
      await toggleChatFavorite(chatId);
      const favorites = await getFavoritesPromptsByUser();
      set({ favoritePrompts: favorites });

      return favorites;
    } catch (error) {
      console.error("Erreur lors de la modification des favoris", error);
      throw error;
    }
  },

  updateConversationLastMessage: (conversationId) => {
    set((state) => ({
      conversations: state.conversations.map((conv) =>
        conv.conversation_id === conversationId
          ? { ...conv, updated_at: new Date().toISOString() }
          : conv,
      ),
      filteredConversations: state.filteredConversations.map((conv) =>
        conv.conversation_id === conversationId
          ? { ...conv, updated_at: new Date().toISOString() }
          : conv,
      ),
    }));
  },
}));

function filterConversations(
  conversations: Conversation[],
  searchTerm: string,
): Conversation[] {
  if (!searchTerm.trim()) return conversations;

  return conversations.filter(
    (conv) =>
      conv.focus_names.some((name) =>
        name.toLowerCase().includes(searchTerm.toLowerCase()),
      ) ||
      conv.social_media_formatted_names.some((name) =>
        name.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
  );
}
