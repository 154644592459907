import { produce } from "immer";
import { create } from "zustand";
import {
  askQuestionToMoodee,
  createFeedback,
  deleteChat,
  deleteChatsAfterDate,
  toggleThumbs,
} from "../lib/askMoodee";
import { Chat } from "../types";
import { useConversationStore } from "./useConversationStore";

type ChatState = {
  currentConversationChats: Chat[] | null;
  chatContent: string;
  answerLoading: boolean;
  answerFinished: boolean;
  modifiedChatId: string | null;
  moodeeWaitMessage: string;
  waitMessageTimeoutIds: number[]; // Store timeout IDs for cleanup

  setCurrentConversationChats: (chats: Chat[] | null) => void;
  setChatContent: (content: string) => void;
  setAnswerLoading: (loading: boolean) => void;
  setAnswerFinished: (finished: boolean) => void;
  setModifiedChatId: (id: string | null) => void;
  setMoodeeWaitMessage: (message: string) => void;
  updateWaitMessages: () => void;
  clearWaitMessageTimeouts: () => void;

  sendMessage: (
    conversationId: string,
    content: string,
    llmModel: string | null,
  ) => Promise<void>;
  deleteMessage: (chatId: string) => Promise<void>;
  deleteChatsAfter: (chatId: string) => Promise<void>;
  updateStreamingMessage: (id: string, chunk: string) => void;
  handleThumbs: (chatId: string, thumbType: "UP" | "DOWN") => Promise<void>;
  handleCreateFeedback: (chatId: string, feedback: string) => Promise<void>;
  toggleFavorite: (chatId: string) => Promise<void>;

  getFavoritePrompts: () => any[];
};

export const useChatStore = create<ChatState>((set, get) => ({
  currentConversationChats: null,
  chatContent: "",
  answerLoading: false,
  answerFinished: true,
  modifiedChatId: null,
  moodeeWaitMessage: "Analyse du prompt...",
  waitMessageTimeoutIds: [],

  setCurrentConversationChats: (chats) =>
    set({ currentConversationChats: chats }),
  setChatContent: (content) => set({ chatContent: content }),
  setAnswerLoading: (loading) => {
    // When setting loading to true, update wait messages
    // When setting to false, clear timeouts
    if (loading) {
      set({ answerLoading: loading });
      get().updateWaitMessages();
    } else {
      get().clearWaitMessageTimeouts();
      set({ answerLoading: loading });
    }
  },
  setAnswerFinished: (finished) => set({ answerFinished: finished }),
  setModifiedChatId: (id) => set({ modifiedChatId: id }),
  setMoodeeWaitMessage: (message) => set({ moodeeWaitMessage: message }),

  // Function to update wait messages with timeouts
  updateWaitMessages: () => {
    const messages = [
      "Analyse du prompt...",
      "Recherche et enrichissement des données...",
      "Raisonnement...",
      "Génération de la réponse...",
    ];

    // Clear any existing timeouts first
    get().clearWaitMessageTimeouts();

    // Set the first message immediately
    set({ moodeeWaitMessage: messages[0] });

    // Function to generate a random duration between 5000 and 8000 ms
    const getRandomDuration = () =>
      Math.floor(Math.random() * (8000 - 5000 + 1) + 5000);

    // Create new timeouts for subsequent messages
    let cumulativeDelay = 0;
    const timeoutIds: number[] = [];

    messages.slice(1).forEach((msg, index) => {
      cumulativeDelay += getRandomDuration();
      const timeoutId = window.setTimeout(() => {
        set({ moodeeWaitMessage: msg });
      }, cumulativeDelay);

      timeoutIds.push(timeoutId);
    });

    // Store the timeout IDs for later cleanup
    set({ waitMessageTimeoutIds: timeoutIds });
  },

  // Function to clear all wait message timeouts
  clearWaitMessageTimeouts: () => {
    const { waitMessageTimeoutIds } = get();
    waitMessageTimeoutIds.forEach((id) => window.clearTimeout(id));
    set({ waitMessageTimeoutIds: [] });
  },

  getFavoritePrompts: () => useConversationStore.getState().favoritePrompts,

  sendMessage: async (conversationId, content, llmModel) => {
    if (!content.trim()) return;

    const userTempId = crypto.randomUUID();
    const moodeeTempId = crypto.randomUUID().toString();
    let realMoodeeId = moodeeTempId;

    set(
      produce<ChatState>((state) => {
        const newChats = state.currentConversationChats
          ? [...state.currentConversationChats]
          : [];
        newChats.push(
          {
            chat_id: userTempId,
            conversation_id: conversationId,
            content: content,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            favorite: false,
            author: "User",
            thumbs: null,
            favorite_summary: null,
          },
          {
            chat_id: moodeeTempId,
            conversation_id: conversationId,
            content: "",
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            author: "Moodee",
            favorite: false,
            thumbs: null,
            favorite_summary: null,
          },
        );
        state.currentConversationChats = newChats;
        state.chatContent = "";
        state.answerLoading = true;
        state.answerFinished = false;
      }),
    );

    // Start the wait message sequence
    get().updateWaitMessages();

    try {
      let accumulatedContent = "";
      let lastUpdateTime = Date.now();
      const UPDATE_INTERVAL = 300;

      await askQuestionToMoodee(
        conversationId,
        content,
        (chunk, metadata) => {
          if (metadata) {
            if (metadata.moodee_id) {
              realMoodeeId = metadata.moodee_id;
            }

            set(
              produce<ChatState>((state) => {
                if (!state.currentConversationChats) return;

                state.currentConversationChats =
                  state.currentConversationChats.map((chat: Chat) => {
                    if (chat.chat_id === userTempId && metadata.user_id) {
                      return { ...chat, chat_id: metadata.user_id };
                    }
                    if (chat.chat_id === moodeeTempId && metadata.moodee_id) {
                      return { ...chat, chat_id: metadata.moodee_id };
                    }
                    return chat;
                  });
              }),
            );
          } else {
            accumulatedContent += chunk;

            const currentTime = Date.now();
            if (currentTime - lastUpdateTime > UPDATE_INTERVAL) {
              // Clear wait message timeouts when we start receiving content
              get().clearWaitMessageTimeouts();

              set(
                produce<ChatState>((state) => {
                  if (!state.currentConversationChats) return;

                  state.answerLoading = false;

                  const chatToUpdate = state.currentConversationChats.find(
                    (chat: Chat) => chat.chat_id === realMoodeeId,
                  );

                  if (chatToUpdate) {
                    chatToUpdate.content += accumulatedContent;
                  }
                }),
              );

              accumulatedContent = "";
              lastUpdateTime = currentTime;
            }
          }
        },
        llmModel,
      );

      if (accumulatedContent.length > 0) {
        set(
          produce<ChatState>((state) => {
            if (!state.currentConversationChats) return;

            const chatToUpdate = state.currentConversationChats.find(
              (chat: Chat) => chat.chat_id === realMoodeeId,
            );

            if (chatToUpdate) {
              chatToUpdate.content += accumulatedContent;
            }
          }),
        );
      }

      set({ answerFinished: true });
    } catch (err) {
      console.error("Erreur lors de l'envoi du message", err);
      // Make sure to clear timeouts on error too
      get().clearWaitMessageTimeouts();
      set({
        answerLoading: false,
        answerFinished: true,
      });
    }
  },

  deleteMessage: async (chatId) => {
    try {
      await deleteChat(chatId);

      set(
        produce<ChatState>((state) => {
          if (!state.currentConversationChats) return;
          state.currentConversationChats =
            state.currentConversationChats.filter(
              (chat: Chat) => chat.chat_id !== chatId,
            );
        }),
      );
    } catch (err) {
      console.error("Erreur lors de la suppression du message", err);
    }
  },

  deleteChatsAfter: async (chatId) => {
    try {
      const { currentConversationChats } = get();
      if (!currentConversationChats) return;

      const modifiedChat = currentConversationChats.find(
        (chat) => chat.chat_id === chatId,
      );

      if (!modifiedChat) return;
      const modifiedDate = new Date(modifiedChat.created_at);

      set(
        produce<ChatState>((state) => {
          if (!state.currentConversationChats) return;

          state.currentConversationChats = state.currentConversationChats
            .filter((chat: Chat) => {
              const chatDate = new Date(chat.created_at);
              return chatDate <= modifiedDate;
            })
            .filter((chat: Chat) => chat.chat_id !== chatId);
        }),
      );

      await deleteChatsAfterDate(chatId);
    } catch (err) {
      console.error("Erreur lors de la suppression des messages après", err);
    }
  },

  updateStreamingMessage: (id, chunk) => {
    set(
      produce<ChatState>((state) => {
        if (!state.currentConversationChats) return;

        const chatToUpdate = state.currentConversationChats.find(
          (chat: Chat) => chat.chat_id === id,
        );

        if (chatToUpdate) {
          chatToUpdate.content += chunk;
        }
      }),
    );
  },

  handleThumbs: async (chatId, thumbType) => {
    try {
      set(
        produce<ChatState>((state) => {
          if (!state.currentConversationChats) return;

          const chatToUpdate = state.currentConversationChats.find(
            (chat: Chat) => chat.chat_id === chatId,
          );

          if (chatToUpdate) {
            chatToUpdate.thumbs =
              chatToUpdate.thumbs === thumbType ? null : thumbType;
          }
        }),
      );

      await toggleThumbs(chatId, thumbType);
    } catch (err) {
      console.error("Erreur lors de la mise à jour des pouces", err);
    }
  },

  handleCreateFeedback: async (chatId, feedback) => {
    try {
      set(
        produce<ChatState>((state) => {
          if (!state.currentConversationChats) return;

          const chatToUpdate = state.currentConversationChats.find(
            (chat: Chat) => chat.chat_id === chatId,
          );

          if (chatToUpdate) {
            chatToUpdate.favorite_summary = feedback;
          }
        }),
      );

      await createFeedback(chatId, feedback);
    } catch (err) {
      console.error("Erreur lors de la mise à jour du feedback", err);
    }
  },

  toggleFavorite: async (chatId) => {
    try {
      set(
        produce<ChatState>((state) => {
          if (!state.currentConversationChats) return;

          const chatToUpdate = state.currentConversationChats.find(
            (chat: Chat) => chat.chat_id === chatId,
          );

          if (chatToUpdate) {
            chatToUpdate.favorite = !chatToUpdate.favorite;
          }
        }),
      );
      await useConversationStore.getState().toggleFavorite(chatId);
    } catch (error) {
      console.error("Erreur lors de la modification des favoris", error);
    }
  },
}));
