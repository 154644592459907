import * as AvatarPrimitive from "@radix-ui/react-avatar";
import * as React from "react";
import { cn, isExternalUrl } from "../lib/utils";
import { User } from "../types";

const AvatarUser: React.FC<{
  user: User | undefined;
  className?: string;
  fontSize?: string;
}> = ({ user, className, fontSize }) => {
  if (!user || !user.first_name || !user.last_name) {
    return (
      <AvatarPrimitive.Root
        className={cn(
          "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
          className,
        )}
      >
        <AvatarPrimitive.Fallback
          className={cn(
            "flex h-full w-full items-center justify-center rounded-full bg-gray-400 font-bold text-white",
            fontSize,
          )}
        >
          ?
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
    );
  }

  // Génère l'URL pour l'image
  const profilePictureSrc = user.profile_picture
    ? isExternalUrl(user.profile_picture) // Vérifie si l'image est une URL externe
      ? user.profile_picture // URL externe (par exemple, photo Google)
      : `${process.env.REACT_APP_STATIC_IMAGE_URL}${user.profile_picture}` // Image locale
    : null;

  return (
    <AvatarPrimitive.Root
      className={cn(
        "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full",
        className,
      )}
    >
      {profilePictureSrc ? (
        <AvatarPrimitive.Image
          src={profilePictureSrc}
          alt={`${user.first_name} ${user.last_name}`}
          className={cn("aspect-square h-full w-full")}
        />
      ) : (
        <AvatarPrimitive.Fallback
          className={cn(
            "flex h-full w-full items-center justify-center rounded-full bg-blue-light font-bold text-dark",
            fontSize,
          )}
        >
          {user.first_name.charAt(0)}
          {user.last_name.charAt(0)}
        </AvatarPrimitive.Fallback>
      )}
    </AvatarPrimitive.Root>
  );
};

export default AvatarUser;
