import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import { addConversationToUser } from "../lib/askMoodee";
import ChatHeader from "./chat/ChatHeader";
import ChatInput from "./chat/ChatInput";
import ChatMessages from "./chat/ChatMessages";
import ChatMessagesLoader from "./chat/ChatMessagesLoader";
import ChatSidebar from "./chat/ChatSidebar";
import NewChatForm from "./chat/NewChatForm";

// Importer les stores Zustand
import { useUIRefs } from "../hooks/useUIRefs";
import { useChatStore } from "../store/useChatStore";
import { useConversationStore } from "../store/useConversationStore";
import { useUIStore } from "../store/useUIStore";

const ChatPage: React.FC = () => {
  // Hooks de base React Router
  const navigate = useNavigate();
  const location = useLocation();

  // Hooks pour les données utilisateur
  const { socialMedias, focuses } = useUser();

  // Références UI
  const { messagesEndRef, textareaRef } = useUIRefs();

  // État du chat depuis le store
  const { currentConversationChats, setCurrentConversationChats } =
    useChatStore();

  // État des conversations depuis le store
  const {
    conversations,
    viewerConversations,
    loadConversationHistory,
    isConversationsLoading,
    loadAllInitialData,
  } = useConversationStore();

  // État de l'UI depuis le store
  const { shouldShowChat, setShouldShowChat, setError } = useUIStore();

  // Initialisation des données
  useEffect(() => {
    // Charger toutes les données initiales en une seule méthode
    loadAllInitialData();
  }, [loadAllInitialData]);

  // Charger une conversation existante
  const handleConversationSelect = useCallback(
    async (conversationId: string) => {
      try {
        navigate(`?conversation_id=${conversationId}`);
        setShouldShowChat(true);

        // Charger l'historique via le store
        const history = await loadConversationHistory(conversationId);
        setCurrentConversationChats(history);
      } catch (err: any) {
        setShouldShowChat(false);
        setError(err.message || "Échec de la récupération de la conversation.");
      }
    },
    [
      navigate,
      loadConversationHistory,
      setCurrentConversationChats,
      setShouldShowChat,
      setError,
    ],
  );

  // Ajouter une conversation à un utilisateur
  const handleAddConversationToUser = useCallback(
    async (conversationId: string, newUserId: string) => {
      try {
        await addConversationToUser(conversationId, newUserId);
        navigate("/chat");
        useConversationStore.getState().loadInitialConversations();
      } catch (error: any) {
        if (error.response) {
          throw error.response.data;
        } else {
          throw new Error("Error adding conversation to user");
        }
      }
    },
    [navigate],
  );

  // Détecter conversation_id dans URL et charger la conversation
  useEffect(() => {
    if (isConversationsLoading) return;

    const urlParams = new URLSearchParams(location.search);
    const convId = urlParams.get("conversation_id");

    if (convId && !currentConversationChats) {
      handleConversationSelect(convId);
    }
    setShouldShowChat(!!convId);
  }, [
    location.search,
    currentConversationChats,
    handleConversationSelect,
    isConversationsLoading,
    setShouldShowChat,
  ]);

  // Trouver la conversation sélectionnée
  const selectedConversation =
    conversations?.find(
      (conv) =>
        conv.conversation_id === currentConversationChats?.[0]?.conversation_id,
    ) ??
    viewerConversations.find(
      (conv) =>
        conv.conversation_id === currentConversationChats?.[0]?.conversation_id,
    );

  // Déterminer le chemin actuel
  const isNewChatRoute = location.pathname === "/chat/new";

  // Rendu du composant
  return (
    <div className="relative h-full p-4">
      <div className="relative z-50 flex h-full">
        <ChatSidebar />

        <div className="mx-auto flex size-full flex-col justify-between">
          {shouldShowChat && selectedConversation ? (
            // Affichage du chat actif
            <>
              <ChatHeader
                handleAddConversationToUser={handleAddConversationToUser}
              />
              {currentConversationChats && (
                <ChatMessages messagesEndRef={messagesEndRef} />
              )}
              <ChatInput textareaRef={textareaRef} />
            </>
          ) : // Affichage du formulaire de nouvelle conversation ou loader
          isNewChatRoute || !shouldShowChat ? (
            <NewChatForm focuses={focuses} socialMedias={socialMedias} />
          ) : (
            <ChatMessagesLoader />
          )}
        </div>
      </div>

      <img
        alt="shadow"
        src="chat-shadow.png"
        className="absolute bottom-0 right-0 z-0 hidden blur-[80px]"
      />
    </div>
  );
};

export default ChatPage;
