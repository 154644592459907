import React from "react";
import { cn } from "../../lib/utils";

// Define proper TypeScript interfaces
export interface Step {
  id: number;
  label: string;
}

interface AnimatedStepperProps {
  steps: Step[];
  currentStep: number;
  onStepChange?: (step: number) => void;
  className?: string;
  allowStepClick?: boolean;
  color: string;
}

// Helpers
const isActive = (stepId: number, currentStep: number) =>
  stepId === currentStep;
const isCompleted = (stepId: number, currentStep: number) =>
  stepId < currentStep;

const AnimatedStepper: React.FC<AnimatedStepperProps> = ({
  steps,
  currentStep,
  onStepChange,
  className,
  color,
  allowStepClick = true,
}) => {
  // Gérer le clic sur une étape
  const handleStepClick = (stepId: number) => {
    if (allowStepClick && onStepChange) {
      onStepChange(stepId);
    }
  };

  return (
    <div className="relative my-6 flex w-full items-center justify-between">
      {/* Connecting lines in background */}
      <div className="absolute left-5 right-5 top-5 flex h-1">
        {steps.map((step, index) => {
          if (index === steps.length - 1) return null;

          const startPercent = (index / (steps.length - 1)) * 100;
          const endPercent = ((index + 1) / (steps.length - 1)) * 100;
          const width = endPercent - startPercent;

          return (
            <React.Fragment key={`line-container-${index}`}>
              {/* Background line (gray) */}
              <div
                className="absolute h-full bg-gray-200"
                style={{
                  left: `${startPercent}%`,
                  width: `${width}%`,
                }}
              />

              {/* Progress line (blue) with transition */}
              <div
                className={cn(
                  `absolute h-full bg-${color} transition-all duration-700 ease-in-out`,

                  isCompleted(step.id, currentStep) ||
                    isActive(step.id, currentStep)
                    ? "opacity-100"
                    : "opacity-0",
                )}
                style={{
                  left: `${startPercent}%`,
                  width: isCompleted(step.id, currentStep) ? `${width}%` : "0%",
                }}
              />
            </React.Fragment>
          );
        })}
      </div>

      {steps.map((step) => {
        return (
          <div
            key={step.id}
            className="z-50 flex flex-col items-center"
            onClick={() => handleStepClick(step.id)}
          >
            {/* Outer circle */}
            <div
              className={cn(
                "relative z-20 flex h-10 w-10 items-center justify-center rounded-xl border-2 transition-all delay-200 duration-300 ease-in-out",
                isCompleted(step.id, currentStep)
                  ? `border-${color} bg-${color}`
                  : isActive(step.id, currentStep)
                    ? `border-${color} bg-white`
                    : "border-gray-200 bg-white",
              )}
            >
              {/* Inner dot */}
              <div
                className={cn(
                  "size-4 rounded-md transition-all delay-200 duration-300 ease-in-out",
                  isCompleted(step.id, currentStep)
                    ? "bg-white"
                    : isActive(step.id, currentStep)
                      ? `bg-${color}`
                      : "bg-gray-200",
                )}
              />
            </div>

            {/* Step label */}
            <div
              className={cn(
                "mt-2 text-center transition-opacity delay-200 duration-300 ease-in-out",
                isCompleted(step.id, currentStep) ||
                  isActive(step.id, currentStep)
                  ? "opacity-100"
                  : "opacity-30",
              )}
            >
              <p className="font-bold">Étape {step.id}</p>
              <p className="text-sm text-gray-600">{step.label}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AnimatedStepper;
